import React, { forwardRef } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/common/24px/icon_search.svg";
import { InputProps } from "./Input";

interface SearchInputProps extends InputProps {
  isOutline?: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, setValue, isOutline, style, ...args }, ref) => {
    return (
      <SearchInputContainer style={style} isOutline={isOutline}>
        <SearchIcon stroke="#C6C8CD" className="searchIcon" />
        <input
          type="text"
          value={value}
          onChange={(e) => setValue && setValue(e.target.value)}
          ref={ref}
          {...args}
        />
      </SearchInputContainer>
    );
  }
);

SearchInput.displayName = "SearchInput";

export default SearchInput;

export const SearchInputContainer = styled.div<{ isOutline?: boolean }>`
  display: flex;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  height: 3.8rem;
  gap: 0.4rem;
  align-items: center;
  background-color: var(--grey-200);
  box-sizing: border-box;

  border: ${({ isOutline }) =>
    isOutline ? "1px solid var(--stroke-light)" : "none"};

  &:hover {
    background-color: var(--grey-300);
  }

  &:focus-within {
    background-color: var(--grey-200);
  }

  .searchIcon {
    width: 2.4rem;
    height: 2.4rem;
  }

  & > input {
    background-color: transparent;
    font-weight: var(--regular);
    font-size: 1.4rem;
    line-height: 2rem;
    color: #171d23;
    width: 100%;

    &::placeholder {
      color: var(--grey-500);
    }
  }
`;
