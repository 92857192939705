import Button from "components/Common/Button/Button";
import {
  Body1Medium,
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
} from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import { ReactComponent as InformationIcon } from "assets/common/24px/icon_information_default.svg";
import Tooltip from "components/Common/Tooltip/Tooltip";
import useModal from "hooks/useModal";
import ActionModal from "components/Common/Modal/ActionModal";
import Input from "components/Common/Input/Input";
import useFileUpload from "hooks/useFileUpload";

const MultipleReceiverConfigForm = () => {
  const { openModal, closeModal, isOpen } = useModal();

  return (
    <>
      {isOpen && <MultipleReceiverSendModal closeModal={closeModal} />}
      <MultipleReceiverConfigFormLayout>
        <Body1SemiBold>수신자</Body1SemiBold>
        <div className="multiple-receiver-config-form-info">
          <Body1Medium>전송</Body1Medium>
          <LeftCase>0건</LeftCase>

          <hr />
          <Body1Medium>잔여 건수</Body1Medium>
          <LeftCase>0건</LeftCase>

          <Button colorType="tertiary" size="small" onClick={openModal}>
            일괄 등록
          </Button>
          <Tooltip description="파일을 업로드 하여 수신자 정보를 일괄 등록할 수 있습니다.">
            <InformationIcon />
          </Tooltip>
        </div>
      </MultipleReceiverConfigFormLayout>
    </>
  );
};

export default MultipleReceiverConfigForm;

const MultipleReceiverConfigFormLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  .multiple-receiver-config-form-info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    > hr {
      width: 0.1rem;
      height: 1.6rem;
      background-color: var(--stroke-light);
      border: none;
    }

    > button {
      margin-left: 1.6rem;
    }
  }
`;

const LeftCase = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

const DescriptionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  ul {
    padding-left: 1.4rem;
    margin: 0;

    > li {
      list-style: outside;
      margin-bottom: 0.5rem;
    }
  }

  ol {
    padding-left: 1.8rem;
    margin: 0;

    > li {
      list-style: decimal;
      margin-bottom: 0.5rem;
    }
    li::marker {
      color: var(--text-default);
      font-size: 1.6rem;
    }
  }
`;

const FileLayout = styled.div`
  button {
    margin-bottom: 1.2rem;
  }

  .file-found-layout {
    display: flex;
    gap: 1.2rem;
    width: 100%;
    align-items: center;
    align-content: center;

    > div:first-child {
      flex: 1;
    }
    button {
      margin: 0;
      padding: 1.2rem 2.2rem;
    }
  }
`;

interface MultipleReceiverSendModalProps {
  closeModal: () => void;
}

const MultipleReceiverSendModal = ({
  closeModal,
}: MultipleReceiverSendModalProps) => {
  const { fileInputProps, inputFor, files } = useFileUpload({
    types: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
    ],
    maxFileCount: 1,
  });

  return (
    <ActionModal
      widthFull
      title="대량 전송"
      handleModalClose={closeModal}
      handleCancelButton={closeModal}
      handleConfirmButton={closeModal}
    >
      <DescriptionLayout>
        <Body1Regular>대량전송이란?</Body1Regular>
        <ul>
          <li>
            동일한 템플릿을 동시에 여러명에게 전송할 수 있습니다. <br />
            (1회 최대 500명)
          </li>
          <li>
            대량전송 엑셀 포맷을 이용하면 템플릿 일부 필드값을 수신자별로
            <br />
            다르게 입력하여 전송할 수 있습니다.
          </li>
          <li>
            수신자 정보를 수정할 경우, 대량전송의 업로드한 파일을 <br />
            수정해야 합니다.
          </li>
        </ul>
      </DescriptionLayout>
      <DescriptionLayout>
        <Body1Regular>이용방법</Body1Regular>
        <ol start={1}>
          <li>
            [포맷 다운로드]를 클릭하여 대량 전송용 엑셀 파일을 다운로드
            <br />
            받습니다.
          </li>
          <li>
            수신자 정보 및 템플릿에 설정된 필드에 미리 작성할 내용을 <br />
            엑셀 파일에 입력 후 저장합니다.
          </li>
          <li>[파일찾기]를 클릭하여 저장한 엑셀 파일을 업로드합니다.</li>
          <li>업로드한 내용이 화면의 수신자 목록에 표시됩니다.</li>
          <li>
            [미리보기] 버튼으로 각 수신자별 입력값이 제대로 반영되었는지
            <br />
            확인 후 전송합니다.
          </li>
        </ol>
      </DescriptionLayout>
      <FileLayout>
        <Button colorType="tertiary" size="medium">
          <Body2Medium>포멧 다운로드</Body2Medium>
        </Button>

        <div className="file-found-layout">
          <Input disabled value={files?.[0]?.name} />
          <Button colorType="tertiary" size="large" {...inputFor}>
            <Body1SemiBold>파일찾기</Body1SemiBold>
          </Button>
          <Input
            type="file"
            style={{
              display: "none",
              width: "0",
            }}
            {...fileInputProps}
          />
        </div>
      </FileLayout>
    </ActionModal>
  );
};
