import React from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { ReactComponent as BellIcon } from "assets/common/24px/icon_bell.svg";
import styled from "styled-components";
import NotificationDropDownItem, {
  NotificationDropDownEmptyMenuItem,
} from "./NotificationDropDownMenuItem";
import Button from "components/Common/Button/Button";
import { Link } from "react-router-dom";
import useNotificationToday from "hooks/queries/notification/useNotificationToday";
import { format } from "date-fns";
import { NOTIFICATION_TYPE_MAP } from "config/notification";

const NotificationDropDownMenu = () => {
  const { data } = useNotificationToday();

  return (
    <DropDownRoot>
      <DropDownTrigger asChild>
        <button>
          <BellIcon />
        </button>
      </DropDownTrigger>
      <DropDownContent align="center">
        <div className="notification-list">
          {data.notificationList.map((notification) => (
            <Link
              to={NOTIFICATION_TYPE_MAP[notification.notificationType]?.link}
              key={notification.notificationUuid}
            >
              <NotificationDropDownItem
                time={format(notification.createdAt, "yyyy-MM-dd hh:mm")}
                title={
                  NOTIFICATION_TYPE_MAP[notification.notificationType]?.text
                }
                content={notification.content}
              />
              <hr />
            </Link>
          ))}
          {data.notificationList.length === 0 && (
            <NotificationDropDownEmptyMenuItem>
              <p>신규 알림이 없습니다.</p>
            </NotificationDropDownEmptyMenuItem>
          )}
        </div>
        <Link to={"/alert/dashboard"}>
          <Button
            colorType="secondary"
            size="medium"
            style={{
              width: "100%",
            }}
          >
            전체 알림 확인하기
          </Button>
        </Link>
      </DropDownContent>
    </DropDownRoot>
  );
};

export default NotificationDropDownMenu;

const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;
  outline: none;
`;

const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  padding: 1.1rem 1.4rem;
  display: flex;
  white-space: nowrap;
`;

const DropDownContent = styled(Dropdown.Content)`
  background: var(--bg-white);
  box-shadow: 0rem 1rem 2rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  height: fit-content;
  width: 30rem;
  padding: 0.8rem;

  .notification-list {
    max-height: 55rem;
    overflow-y: auto;

    div > hr {
      background-color: var(--stroke-light);
      height: 0.1rem;
      outline: none;
      border: none;
    }

    > div:last-child > hr {
      display: none;
    }
  }
`;
