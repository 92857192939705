import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useServicePlanFreeQuery = (organizationUuid?: string) => {
  return useQuery({
    ...queries.payment.free(organizationUuid),
    enabled: !!organizationUuid,
    retry: 1,
  });
};

export default useServicePlanFreeQuery;
