import { device } from "components/styles";
import styled from "styled-components";

export const PaymentDashboardContainer = styled.div`
  .ratePlanContainer {
    display: grid;
    column-gap: 1.8rem;
    row-gap: 6.4rem;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);

    @container (${device.md}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @container (${device.sm}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @container (${device.xs}) {
      grid-template-columns: 1fr;
    }
  }
`;

export const ServiceDiscountContainer = styled.div`
  display: flex;
  box-sizing: content-box;
  flex-direction: column;
  gap: 4rem;
  border-radius: 2rem;
  border: 1px solid var(--grey-300);
  background: var(--bg-white);
  padding: 4rem 0;
  align-items: center;

  .tableContainer {
    gap: 1.2rem;

    .rowContainer {
      padding: 0.6rem 0;
    }

    .labelRow {
      height: 7.2rem;
      color: var(--text-default);
    }

    .rowItemLabel {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.6rem;
      letter-spacing: -0.54px;
    }

    .discountRateRow {
      .rowItem {
        color: var(--grey-600);
      }
    }

    .blueRow {
      .rowItem {
        color: var(--text-default);
      }
    }
  }
`;

export const PlanItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2rem;

  min-height: 12.6rem;
`;

export const FreePlanItemContainer = styled(PlanItemContainer)`
  justify-content: flex-end;

  & > .content {
    padding: 1.5rem;
    align-self: center;
  }
`;
