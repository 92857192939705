import { MenuItems } from ".";
import HomeIcon from "assets/common/24px/icon_home.svg";
import ActiveHomeIcon from "assets/common/24px/icon_home_active.svg";
import TemplateIcon from "assets/common/24px/icon_template.svg";
import ActiveTemplateIcon from "assets/common/24px/icon_template_active.svg";
import ContractIcon from "assets/common/24px/icon_contract.svg";
import ActiveContractIcon from "assets/common/24px/icon_contract_active.svg";
import SettingsIcon from "assets/common/24px/icon_settings.svg";
import ActiveSettingsIcon from "assets/common/24px/icon_settings_active.svg";

// TODO routes에 맞게 수정 필요
// subMenu > include는 subMenu의 path(to)는 아니지만 해당 subMenu가 활성화되야 하는 경우 추가
export const MENU_ITEMS: MenuItems[] = [
  {
    label: "홈",
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    to: "/dashboard",
    include: ["/notice", "/alert"],
    subMenu: [
      {
        label: "내 정보",
        to: "/profile/information",
        include: ["/profile/information"],
      },
      {
        label: "내 서명",
        to: "/profile/sign",
        include: ["/profile/sign"],
      },
      {
        label: "가입된 조직",
        to: "/profile/invited-organization",
        include: ["/profile/invited-organization"],
      },
    ],
  },
  {
    label: "템플릿 관리",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
    to: "/template/dashboard",
    subMenu: [
      {
        label: "전체",
        to: "/template/dashboard",
        include: ["/template/dashboard"],
      },
      {
        label: "내가 만든 템플릿",
        to: "/template/dashboard/me",
        include: ["/template/dashboard/me"],
      },
      {
        label: "즐겨찾기",
        to: "/template/dashboard/bookmark",
        include: ["/template/dashboard/bookmark"],
      },
      {
        label: "템플릿 스토어",
        to: "/template/store",
        include: ["/template/store"],
      },
    ],
  },
  {
    label: "계약 관리",
    icon: ContractIcon,
    activeIcon: ActiveContractIcon,
    to: "/contract/dashboard/normal",
    subMenu: [
      {
        label: "일반 계약",
        to: "/contract/dashboard/normal",
        include: ["/contract/dashboard/normal"],
      },
      {
        label: "Direct URL",
        to: "/contract/dashboard/direct-url",
        include: ["/contract/dashboard/direct-url"],
      },
    ],
  },
  {
    label: "설정",
    icon: SettingsIcon,
    activeIcon: ActiveSettingsIcon,
    to: "/setting/payment/dashboard",
    subMenu: [
      {
        label: "결제 관리",
        to: "/setting/payment/dashboard",
        include: [
          "/setting/payment/billing",
          "/setting/payment/dashboard",
          "/setting/payment/history",
          "/setting/payment/billing/complete",
        ],
      },
      {
        label: "일반 설정",
        to: "/setting/organization",
        include: ["/setting/organization", "/setting/service"],
      },
      {
        label: "멤버/그룹 관리",
        to: "/setting/member",
        include: ["/setting/member", "/setting/group"],
      },
      {
        label: "간편 설정",
        to: "/setting/contact",
        include: ["/setting/contact", "/setting/list-box-db", "/setting/mail"],
      },
      {
        label: "문의하기",
        to: "/setting/support",
        include: ["/setting/support", "/setting/faq"],
      },
    ],
  },
  {
    label: "API",
    to: "/api/dashboard",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
  },
];
