const saveFile = (file: File) => {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};

export default saveFile;
