import React from "react";
import styled from "styled-components";
import { ReactComponent as TrashIcon } from "assets/common/30px/icon_trash.svg";
import { ReactComponent as StarIcon } from "assets/common/30px/icon_star.svg";
import useSignaturesUnFavoritesMutation from "hooks/mutations/signature/useSignaturesUnFavoritesMutation";
import useSignaturesFavoritesMutation from "hooks/mutations/signature/useSignaturesFavoritesMutation";
import useSignaturesDeleteMutation from "hooks/mutations/signature/useSignaturesDeleteMutation";

interface SignItemProps {
  url: string;
  id: string;
  isFavorite: boolean;
}

const SignItem = ({ url, id, isFavorite }: SignItemProps) => {
  const { mutate: unFavoritesMutate } = useSignaturesUnFavoritesMutation();
  const { mutate: favoritesMutate } = useSignaturesFavoritesMutation();
  const { mutate: deleteMutate } = useSignaturesDeleteMutation();

  const handleDelete = () => {
    deleteMutate({ id });
  };

  const handleFavorite = () =>
    isFavorite ? unFavoritesMutate({ id }) : favoritesMutate({ id });

  return (
    <SignItemLayout>
      <Overlay>
        <div className="sign-item-overlay-icon-wrapper">
          <TrashIcon color="#ffffff" onClick={handleDelete} />
          <StarIcon
            onClick={handleFavorite}
            color={isFavorite ? "rgba(255, 214, 0, 1)" : "var(--bg-white)"}
          />
        </div>
      </Overlay>
      <img src={url} alt="sign_image" />
    </SignItemLayout>
  );
};

export default SignItem;

const SignItemLayout = styled.li`
  height: 22rem;
  border-radius: 2rem;
  border: 0.1rem solid var(--stroke-dark);
  position: relative;
  overflow: hidden;
  :hover {
    div {
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: #00000020;

  .sign-item-overlay-icon-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 14px;
    > svg {
      cursor: pointer;
    }
  }
`;
