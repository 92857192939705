import React, { useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as LogoIcon } from "assets/common/icon_logo.svg";
import { ReactComponent as RightIcon } from "assets/common/24px/icon_right_default.svg";

import Button from "components/Common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ReactComponent as MenuIcon } from "assets/common/icon_menu.svg";
import { Board } from "components/Common/Board/Board";
import Divider from "components/Common/Divider/Divider";
import { Title1 } from "components/TextStyle";
import { useBoundaryInView } from "components/Common/BoundaryInView/BoundaryInViewProvider";

const HEADER_MENU = [
  {
    name: "소개",
    href: "#introduce",
  },
  {
    name: "간인",
    href: "#ganin",
  },
  {
    name: "사용법",
    href: "#usage",
  },
  {
    name: "API",
    href: "#api",
  },
  {
    name: "요금제",
    href: "/plan",
  },
  {
    name: "문의하기",
    href: "/support",
  },
];

const AuthHeader = () => {
  const breakpoint = useBreakpoint();

  return (
    <HeaderLayout>
      <Link to={"/"}>
        <LogoIcon
          style={{
            cursor: "pointer",
          }}
        />
      </Link>
      {breakpoint === "lg" && <DesktopMenu />}
      {breakpoint !== "lg" && <MobileMenu />}
    </HeaderLayout>
  );
};

export default AuthHeader;

const HeaderLayout = styled.header`
  background-color: var(--bg-white);
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  border-bottom: 1px solid var(--grey-300);
  background: var(--bg-white);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  height: 6.8rem;
  box-sizing: border-box;
  width: 100%;

  z-index: 1000;
`;

const MobileMenu = () => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setToggle((pre) => !pre);
  };

  return (
    <>
      <button onClick={handleToggle}>
        <MenuIcon />
      </button>
      {toggle && (
        <MobileMenuLayout>
          <div>
            <ul className="menu-auth">
              <Button
                colorType="primary"
                size="large"
                onClick={() => navigate("/auth/sign-in")}
              >
                로그인
              </Button>
              <Button
                colorType="tertiary"
                size="large"
                onClick={() => navigate("/auth/sign-up")}
              >
                회원가입
              </Button>
            </ul>

            <ul>
              {HEADER_MENU.map((menu) => {
                return (
                  <>
                    <a href={menu.href} onClick={handleToggle} key={menu.name}>
                      <li>
                        <Title1>{menu.name}</Title1>
                        <RightIcon />
                      </li>
                    </a>
                    <Divider />
                  </>
                );
              })}
            </ul>
          </div>
        </MobileMenuLayout>
      )}
    </>
  );
};

const MobileMenuLayout = styled(Board)`
  position: absolute;
  background-color: var(--bg-white);
  top: 6.8rem;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;

  > div {
    padding: 4rem;
  }

  .menu-auth {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    margin-bottom: 6rem;
    > button {
      width: 100%;
    }
  }

  a > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  hr {
    margin: 1.6rem 0 3.2rem 0;
  }
`;

const DesktopMenu = () => {
  const { activeSection } = useBoundaryInView();

  return (
    <>
      <MenuListLayout>
        <ul>
          {HEADER_MENU.map((menu) => {
            return (
              <a href={menu.href} key={menu.name}>
                <MenuLink isActive={activeSection === menu.href}>
                  {menu.name}
                </MenuLink>
              </a>
            );
          })}
        </ul>
      </MenuListLayout>

      <MenuContainer>
        <div className="profileContainer">
          <Link to={"/auth/sign-in"}>
            <Button colorType="secondary" size="medium">
              로그인
            </Button>
          </Link>
        </div>
        <div className="logoutContainer">
          <Link to={"/auth/sign-up"}>
            <Button colorType="ghost" size="medium">
              회원가입
            </Button>
          </Link>
        </div>
      </MenuContainer>
    </>
  );
};

const MenuLink = styled.li<{
  isActive?: boolean;
}>`
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 4px solid var(--main-color);
    `}
`;

export const MenuContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  .profileContainer {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
  }

  .logoutContainer {
    display: flex;
    gap: 0.2rem;
    cursor: pointer;
    align-items: center;

    .logoutText {
      color: var(--grey-600);
    }
  }
`;

const MenuListLayout = styled.nav`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ul {
    display: flex;
    gap: 2rem;
    list-style: none;
    text-align: center;
    li {
      display: block;
      padding: 0.6rem;
      width: 8rem;
      height: 100%;
      cursor: pointer;
      color: var(--grey-600);
    }
  }
`;
