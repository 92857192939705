import { useCallback, useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  // 모달 열기
  const openModal = useCallback(() => {
    setIsOpen(() => true);
  }, []);

  // 모달 닫기
  const closeModal = useCallback(() => {
    setIsOpen(() => false);
  }, []);

  return {
    openModal,
    closeModal,
    isOpen,
  };
};

export default useModal;
