import { Body2SemiBold, Header1 } from "components/TextStyle";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { PLAN_BOTTOM_TEXT } from "./PlanConstant";
import PlanBottom from "./PlanBottom";

interface RatePlanItemProps {
  title: "Free" | "Basic" | "Standard" | "Premium 간인";
  subTitle: string;
  children: ReactNode;
}

const RatePlanItem = ({ title, subTitle, children }: RatePlanItemProps) => {
  const planBottomText =
    PLAN_BOTTOM_TEXT[title === "Premium 간인" ? "Premium" : title];
  const isBlue = title === "Standard";
  const isPremium = title === "Premium 간인";

  return (
    <RatePlanItemWrapper>
      {isBlue && (
        <div className="activeLabel">
          <Body2SemiBold>가장 많이 사용</Body2SemiBold>
        </div>
      )}
      {isPremium && (
        <div className="activeLabel premiumLabel">
          <Body2SemiBold>Premium</Body2SemiBold>
        </div>
      )}
      <RatePlanItemContainer $isActive={isBlue}>
        <PlanHeaderContainer>
          <Header1 className="planTitle">{title}</Header1>
          <Body2SemiBold className="planSubtitle">{subTitle}</Body2SemiBold>
        </PlanHeaderContainer>

        <div className="line" />

        <div className="planContentContainer">{children}</div>

        <PlanBottomContainer>
          {planBottomText.map((e, idx) => {
            return <PlanBottom key={idx} item={e} />;
          })}
        </PlanBottomContainer>
      </RatePlanItemContainer>
    </RatePlanItemWrapper>
  );
};

export default RatePlanItem;

export const RatePlanItemWrapper = styled.div`
  position: relative;

  .activeLabel {
    padding: 0.8rem 2rem;
    height: 6.7rem;
    border-radius: 10px 10px 0px 0px;
    background: var(--primary-500);
    width: fit-content;
    box-sizing: border-box;
    color: var(--bg-white);
    position: absolute;
    left: 0;
    top: -3.8rem;
  }

  .premiumLabel {
    background: var(--text-default);
  }

  button {
    width: 100%;
  }
`;

export const RatePlanItemContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  height: fit-content;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 3rem;
  border: ${({ $isActive }) => $isActive && "1px solid var(--primary-500)"};
  background: var(--bg-white);
  gap: 2rem;
  position: relative;

  .line {
    width: 100%;
    height: 0.1rem;
    background-color: var(--grey-300);
  }

  .planContentContainer {
    width: 100%;
  }
`;

export const PlanHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.9rem;

  .planSubtitle {
    color: var(--grey-600);
    white-space: pre-wrap;
  }
`;

export const PlanBottomContainer = styled.div`
  padding: 2rem;
  gap: 0.6rem;
  border-radius: 1.4rem;
  background: var(--grey-100);
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;
