import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getPaymentsHistory,
  getPaymentsPaymentMethodAll,
  getPaymentsProfile,
  getPaymentsServicePlan,
  getPaymentsServicePlanFree,
} from "apis/payment";

export const paymentQueryKey = createQueryKeys("payment", {
  plan: {
    queryKey: null,
    queryFn: getPaymentsServicePlan,
  },
  free: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsServicePlanFree(organizationUuid);
    },
  }),
  method: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsPaymentMethodAll(organizationUuid);
    },
  }),
  history: (params: GetPaymentsHistoryReq) => ({
    queryKey: [params],
    queryFn: () => {
      if (!params.organizationUuid) return;
      return getPaymentsHistory(params);
    },
  }),
  profile: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsProfile(organizationUuid);
    },
  }),
});
