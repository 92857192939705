import {
  Body1Medium,
  Body2Regular,
  Body3Regular,
  SubTitle,
} from "components/TextStyle";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";

interface TemplateInfoProps {
  createAt: Date;
  createdUserName: string;
  email: string;
  status: "TEMP" | "COMPLETE";
  pageCount: number;
  receiverCnt: number;
}

const TemplateInfo = ({
  createAt,
  createdUserName,
  email,
  status,
  pageCount,
  receiverCnt,
}: TemplateInfoProps) => {
  const STATUS_MAP = {
    TEMP: "임시",
    COMPLETE: "완료",
  };

  return (
    <div>
      <SubTitle>기본정보</SubTitle>
      <DefaultInfoList>
        <DefaultInfoItem>
          <Body1Medium>템플릿 생성 시간</Body1Medium>
          <Body2Regular>{format(createAt, "yyyy년 M월 d일 H:mm")}</Body2Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body1Medium>템플릿 생성자</Body1Medium>
          <Body2Regular>
            {createdUserName}
            <strong>({email})</strong>
          </Body2Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body1Medium>템플릿 상태</Body1Medium>
          <Body2Regular>
            <strong>{STATUS_MAP[status]}</strong>
          </Body2Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body1Medium>페이지 수</Body1Medium>
          <Body2Regular>{pageCount}</Body2Regular>
        </DefaultInfoItem>
      </DefaultInfoList>

      <SubTitle>총 {receiverCnt}명의 수신자가 설정되어있습니다.</SubTitle>
      <Body3Regular>
        (동시전송, 대량전송 {receiverCnt > 1 ? "불가능" : "가능"})
      </Body3Regular>
    </div>
  );
};

export default TemplateInfo;

const DefaultInfoItem = styled.li`
  padding: 2rem;
  display: flex;
  align-items: center;
  > :first-child {
    width: 14rem;
  }
`;

const DefaultInfoList = styled.ul`
  margin-bottom: 3rem;
  :last-child {
    hr {
      display: none;
    }
  }
  strong {
    font-weight: 400;
    color: var(--text-middle-emphasis);
  }
`;
