import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postTemplateByIdFavoriteById } from "apis/template";
import { queries } from "config/queryKey";

const useTemplateFavoriteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postTemplateByIdFavoriteById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.template.list._def,
      });
    },
  });
};

export default useTemplateFavoriteMutation;
