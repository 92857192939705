import {
  GetQuickActionResponse,
  PatchQuickActionRequest,
} from "models/QuickAction";
import getAPIResponseData from "utils/getAPIResponse";

export const getQuickAction = () => {
  return getAPIResponseData<GetQuickActionResponse>({
    url: "/quick-actions",
    method: "GET",
  });
};

export const patchQuickAction = (data: PatchQuickActionRequest) => {
  return getAPIResponseData({
    url: "/quick-actions",
    method: "PATCH",
    data,
  });
};
