import { MainContentLayout } from "layout/DefaultLayout";
import React, { useEffect } from "react";
import LandingNavigation from "../LandingNavigation";
import { SupportBox } from "pages/setting/support";
import styled from "styled-components";
import { Board } from "pages/dashboard/style";
import { useBoundaryInView } from "components/Common/BoundaryInView/BoundaryInViewProvider";

const LandingSupportPage = () => {
  const { setActiveSection } = useBoundaryInView();

  useEffect(() => {
    setActiveSection("/support");
  }, []);

  return (
    <MainContentLayout>
      <LandingNavigation />
      <SupportBoard>
        <SupportBox />
      </SupportBoard>
    </MainContentLayout>
  );
};

export default LandingSupportPage;

const SupportBoard = styled(Board)`
  border-radius: 2rem;
  padding: 8rem;
`;
