import Button from "components/Common/Button/Button";
import {
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
} from "components/TextStyle";
import useFileUpload from "hooks/useFileUpload";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ImageIcon } from "assets/common/20px/icon_image.svg";
import { Image, Layer, Stage } from "react-konva";
import * as Slider from "@radix-ui/react-slider";
import Konva from "konva";
import { Image as ImageRefType } from "konva/lib/shapes/Image";
import { ReactComponent as RefreshIcon } from "assets/common/30px/icon_refresh.svg";
import { Stage as StageRefType } from "konva/lib/Stage";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import resizeImage from "utils/resizeImage";
import konvaToImage from "utils/konvaToImage";

interface UploadStampContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const UploadStampContent = ({
  closeModal,
  signMode = false,
  sign,
}: UploadStampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const { files, fileInputProps, inputFor, removeFile, isError } =
    useFileUpload({
      types: ["image/jpeg", "image/png", "image/jpg"],
      size: 2,
      maxFileCount: 1,
      limitNameLength: 40,
    });
  const [contrast, setContrast] = useState(0);
  const [rotation, setRotation] = useState<number>(0);
  const stageRef = useRef<StageRefType | null>(null);

  const file = files?.[0];
  const isFileSelected = !!file;

  const handleRotateClick = () => {
    setRotation((prevRotation) => {
      const newRotation = (prevRotation + 90) % 360;
      return newRotation === 360 ? 0 : newRotation;
    });
  };

  const handleSaveClick = async () => {
    if (!stageRef?.current) return;

    const scaleSize = resizeImage(
      {
        width: stageRef.current.width(),
        height: stageRef.current.height(),
      },
      {
        width: 300,
        height: 300,
      }
    );

    const file = await konvaToImage(stageRef.current, {
      width: 300,
      height: 300,
      ...scaleSize,
    });

    if (signMode && sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  const handleResetImageClick = () => {
    removeFile(0);
    setContrast(0);
    setRotation(0);
  };

  return (
    <>
      <Body1Regular>
        실제 도장을 찍은 사진을 업로드 해 주세요. (2MB이하의 이미지 파일)
      </Body1Regular>
      <UploadButton
        colorType={file ? "tertiary" : "primary"}
        size="medium"
        {...(!isFileSelected ? inputFor : {})}
      >
        <input type="file" {...fileInputProps} />
        <UploadName>
          {isFileSelected ? file.name : "도장 이미지 업로드 하기"}
          {isFileSelected && <a onClick={handleResetImageClick}>취소</a>}
        </UploadName>
      </UploadButton>
      <EmptyImagePreview isError={isError}>
        {isFileSelected ? (
          <Stage width={150} height={150} ref={stageRef}>
            <Layer>
              <ImagePreview
                file={file}
                contrast={contrast}
                rotation={rotation}
              />
            </Layer>
          </Stage>
        ) : (
          <ImageIcon width={42} height={42} />
        )}
      </EmptyImagePreview>
      {isError && (
        <Body3Regular
          style={{
            marginTop: "0.6rem",
            color: "#FA4D56",
          }}
        >
          이미지 파일명이 길어 업로드가 어렵습니다. 파일명 변경 후 다시
          적용해주세요
        </Body3Regular>
      )}
      <ImageEditFooter>
        {isFileSelected && (
          <div>
            <Body2Medium>약한 대비</Body2Medium>
            <SliderRoot
              className="SliderRoot"
              defaultValue={[0]}
              max={100}
              min={-100}
              step={1}
              onValueChange={(value) => {
                setContrast(value[0]);
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </SliderRoot>
            <Body2Medium>강한 대비</Body2Medium>
          </div>
        )}

        <Button
          colorType="tertiary"
          size="medium"
          onClick={handleRotateClick}
          disabled={!isFileSelected}
        >
          <RefreshIcon />
          이미지 회전하기
        </Button>
      </ImageEditFooter>
      <ModalFooter signMode={signMode}>
        {signMode ? (
          <>
            <Button colorType="tertiary" size="large" onClick={closeModal}>
              취소
            </Button>
            <Button colorType="primary" size="large" onClick={handleSaveClick}>
              확인
            </Button>
          </>
        ) : (
          <Button
            colorType="primary"
            size="large"
            onClick={handleSaveClick}
            disabled={!isFileSelected}
          >
            도장 이미지 업로드 하기
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default UploadStampContent;

const ModalFooter = styled.div<{ signMode?: boolean }>`
  display: ${({ signMode }) => (signMode ? "flex" : "block")};
  justify-content: ${({ signMode }) => (signMode ? "center" : "unset")};
  margin: 2rem 0;
  margin-top: ${({ signMode }) => (signMode ? "5.6rem" : "2rem")};
  gap: ${({ signMode }) => (signMode ? "3rem" : "unset")};

  > button {
    width: ${({ signMode }) => (signMode ? "24rem" : "unset")};
    display: block;
    margin: ${({ signMode }) => (signMode ? "unset" : "auto")};
  }
`;

const ImageEditFooter = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  > div:first-child {
    display: flex;
    gap: 1rem;

    position: absolute;

    left: 50%;
    transform: translateX(-50%);
  }

  > button {
    margin-left: auto;
  }
`;

interface ImagePreviewProps {
  file: File;
  contrast: number;
  rotation: number;
}

const ImagePreview = ({ file, contrast, rotation }: ImagePreviewProps) => {
  const url = window.URL.createObjectURL(file);
  const img = new window.Image();
  img.src = url;
  const ref = useRef<ImageRefType>(null);

  if (ref.current) {
    ref.current.cache();
    ref.current.contrast(contrast);
    // Image 컴포넌트에서 props로 contrast는 지원하지 않음 따라서 ref를 이용해 설정해야함 cache 선행 필수
    ref.current.rotation(rotation);
  }

  return (
    <Image
      image={img}
      width={150}
      height={150}
      filters={[Konva.Filters.Contrast]}
      ref={ref}
      x={75}
      y={75}
      offsetX={75}
      offsetY={75}
    />
  );
};

const EmptyImagePreview = styled.div<{ isError: boolean }>`
  background-color: ${({ isError }) =>
    isError ? "#FFF9F9" : "var(--primary-100)"};

  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ isError }) =>
    isError
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DB5257FF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235B96FFFF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};

  margin-top: 2rem;

  svg {
    color: ${({ isError }) => (isError ? "#DB5257" : "#5b96ff")};
    opacity: 0.2;
  }
`;

const UploadButton = styled(Button)`
  margin-top: 2rem;

  input {
    display: none;
  }
`;

const UploadName = styled(Body1SemiBold)`
  display: flex;
  gap: 1rem;
`;

const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;

  .SliderTrack {
    background-color: #f2f4f8;
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 0.6rem;
  }

  .SliderThumb {
    display: block;
    width: 3rem;
    height: 1.2rem;
    background-color: var(--blue-500);
    border-radius: 10px;
  }
`;
