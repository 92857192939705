import React from "react";
import { Circle, Ellipse, Rect } from "react-konva";

interface ShapeProps {
  width: number;
  height: number;
  strokeWidth: number;
}

export const STAMP_SHAPE = {
  CIRCLE: {
    width: 108,
    height: 108,
    OutlineStamp: ({ width, height, strokeWidth }: ShapeProps) => (
      <Circle
        x={width / 2}
        y={height / 2}
        width={width}
        height={height}
        radius={width / 2 - strokeWidth}
        stroke="#E40E14"
        strokeWidth={strokeWidth}
      />
    ),
  },
  ELLIPSE: {
    width: 54,
    height: 84,
    OutlineStamp: ({ width, height, strokeWidth }: ShapeProps) => (
      <Ellipse
        x={width / 2}
        y={height / 2}
        radiusX={width / 2 - strokeWidth}
        radiusY={height / 2 - strokeWidth}
        stroke="#E40E14"
        strokeWidth={strokeWidth}
      />
    ),
  },
  SQUARE: {
    width: 108,
    height: 108,
    OutlineStamp: ({ width, height, strokeWidth }: ShapeProps) => (
      <Rect
        width={width - strokeWidth}
        height={height - strokeWidth}
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        stroke="#E40E14"
        strokeWidth={strokeWidth}
      />
    ),
  },
  RECTANGLE: {
    width: 108,
    height: 76,
    OutlineStamp: ({ width, height, strokeWidth }: ShapeProps) => (
      <Rect
        width={width - strokeWidth}
        height={height - strokeWidth}
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        stroke="#E40E14"
        strokeWidth={strokeWidth}
      />
    ),
  },
};

export interface TEXT_INFO_TYPE {
  textLength: number;
  shapes: {
    stamp: (typeof STAMP_SHAPE)[keyof typeof STAMP_SHAPE];
    chunkSize: number;
    fontSize: number;
  }[];
}

export const TEXT_INFO = [
  {
    textLength: 1,
    shapes: [
      {
        id: "square-1",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 1,
        fontSize: 50,
      },
      {
        id: "circle-1",
        stamp: STAMP_SHAPE.CIRCLE,
        chunkSize: 1,
        fontSize: 50,
      },

      {
        id: "ellipse-1",
        stamp: STAMP_SHAPE.ELLIPSE,
        chunkSize: 1,
        fontSize: 28,
      },
    ],
  },
  {
    textLength: 2,
    shapes: [
      {
        id: "square-2",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 2,
        fontSize: 40,
      },
      {
        id: "circle-2",
        stamp: STAMP_SHAPE.CIRCLE,
        chunkSize: 2,
        fontSize: 40,
      },
      {
        id: "ellipse-2",
        stamp: STAMP_SHAPE.ELLIPSE,
        chunkSize: 1,
        fontSize: 24,
      },
    ],
  },
  {
    textLength: 3,
    shapes: [
      {
        id: "square-3",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 2,
        fontSize: 36,
      },
      {
        id: "circle-3",
        stamp: STAMP_SHAPE.CIRCLE,
        chunkSize: 2,
        fontSize: 36,
      },
      {
        id: "ellipse-3",
        stamp: STAMP_SHAPE.ELLIPSE,
        chunkSize: 1,
        fontSize: 20,
      },
    ],
  },
  {
    textLength: 4,
    shapes: [
      {
        id: "square-4",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 2,
        fontSize: 36,
      },
      {
        id: "circle-4",
        stamp: STAMP_SHAPE.CIRCLE,
        chunkSize: 2,
        fontSize: 36,
      },
    ],
  },
  {
    textLength: 5,
    shapes: [
      {
        id: "rectangle-5",
        stamp: STAMP_SHAPE.RECTANGLE,
        chunkSize: 3,
        fontSize: 24,
      },
    ],
  },
  {
    textLength: 6,
    shapes: [
      {
        id: "rectangle-6",
        stamp: STAMP_SHAPE.RECTANGLE,
        chunkSize: 3,
        fontSize: 24,
      },
    ],
  },
  {
    textLength: 7,
    shapes: [
      {
        id: "rectangle-7",
        stamp: STAMP_SHAPE.RECTANGLE,
        chunkSize: 4,
        fontSize: 24,
      },
    ],
  },
  {
    textLength: 8,
    shapes: [
      {
        id: "square-8",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 3,
        fontSize: 30,
      },
    ],
  },
  {
    textLength: 9,
    shapes: [
      {
        id: "square-9",
        stamp: STAMP_SHAPE.SQUARE,
        chunkSize: 3,
        fontSize: 30,
      },
    ],
  },
] as const;
