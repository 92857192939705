import { Board as DefaultBoard } from "components/Common/Board/Board";
import styled from "styled-components";

export const FastActionLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin-bottom: 0.5rem;

  overflow-x: auto;

  /* 2개의 개체만 있을때 */
  > div:nth-child(1):nth-last-child(2),
  > div:nth-child(2):nth-last-child(1) {
    flex-grow: 2;
  }

  /* 3개의 개체가 있을때 */
  > div:nth-child(1):nth-last-child(3) {
    flex-grow: 2;
  }
`;

export const BoardListLayout = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Board = styled(DefaultBoard)`
  padding: 4rem;
`;

export const BoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  > a {
    text-decoration: underline;
    color: var(--text-tertiary);
  }
`;

export const DashBoardPageLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 71%;
  }

  > div:last-child {
    width: 29%;
  }
`;

export const NoticeLayout = styled.div`
  max-width: 52rem;
  height: 100%;

  .notice-title {
    margin-bottom: 4rem;

    display: flex;
    justify-content: space-between;
  }

  .notice-title__more {
    > a {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      color: var(--text-secondary);
    }
  }
`;

export const NoticeListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  > hr {
    height: 0.1rem;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--stroke-light);
    margin: 0;
  }

  > hr:last-child {
    display: none;
  }
`;
