import React, { useState } from "react";
import { FaqContainer, FaqItemContainer, SearchInputContainer } from "./styles";
import UpIcon from "assets/common/24px/icon_up.svg";
import DownIcon from "assets/common/24px/icon_down_default.svg";
import { ReactComponent as SearchIcon } from "assets/common/24px/icon_search.svg";
import SettingLayout from "components/Setting/Layout/SettingLayout";
import { useQuery } from "@tanstack/react-query";
import { getInquiriesQnas } from "apis/inquiries";

const SettingFAQPage = () => {
  return (
    <SettingLayout type="support" state="faq">
      <FaqSection />
    </SettingLayout>
  );
};

export default SettingFAQPage;

export const FaqSection = () => {
  const [searchValue, setSearchValue] = useState("");

  const { data: qnasData, isFetching } = useQuery({
    queryKey: ["inquiryQnas", searchValue],
    queryFn: () => getInquiriesQnas(searchValue),
  });
  return (
    <FaqContainer>
      <SearchInputContainer>
        <div className="inputContainer">
          <SearchIcon stroke="#C6C8CD" className="searchIcon" />
          <input
            type="text"
            placeholder="무엇이든 찾아보세요"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </SearchInputContainer>
      <div className="faqItemContainer">
        {!isFetching && (
          <>
            {qnasData?.map((item, idx) => {
              return (
                <FaqItem key={idx} title={item.title} content={item.content} />
              );
            })}
          </>
        )}
      </div>
    </FaqContainer>
  );
};

interface FaqProps {
  title: string;
  content: string;
}

const FaqItem = ({ title, content }: FaqProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FaqItemContainer onClick={() => setIsOpen(!isOpen)}>
      <div className="faqTitleContainer">
        <div className="faqTitle">{title}</div>
        <img src={isOpen ? UpIcon : DownIcon} alt="arrowIcon" />
      </div>

      {isOpen && <div className="faqContent">{content}</div>}
    </FaqItemContainer>
  );
};
