import { Board } from "components/Common/Board/Board";
import Button from "components/Common/Button/Button";
import React, { useRef, useState } from "react";
import { Document } from "react-pdf";
import styled from "styled-components";
import TemplateInfo from "./TemplateInfo";
import BoundaryInViewProvider from "components/Common/BoundaryInView/BoundaryInViewProvider";
import TemplateMainPdfItem from "./TemplateMainPdfItem";
import TemplateListPdfItem from "./TemplateListPdfItem";
import { ReactComponent as ArrowLeft } from "assets/common/36px/icon_left_large.svg";
import { Header1 } from "components/TextStyle";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import useUserState from "hooks/recoil/useUserState";
import { useParams } from "react-router-dom";
import { getTemplateDataExcel } from "apis/template";
import saveFile from "utils/saveFile";

const TemplateDetailPage = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [user] = useUserState();
  const { templateId } = useParams();

  const { data } = useTemplateDetailInfoQuery({
    organizationId: user.organization,
    templateId: templateId as string,
  });

  if (!data) return;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleDownloadExcel = async () => {
    try {
      const res = await getTemplateDataExcel({
        organizationId: user.organization ?? "",
        templateId: templateId as string,
      });

      const file = new File([res.data], "template_file.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // saveFile 함수를 사용하여 파일을 저장합니다.
      saveFile(file);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BoundaryInViewProvider>
      <div>
        <TemplateDetailHeader>
          <div>
            <ArrowLeft />
            <Header1>{data.contractTemplateDetails.templateName}</Header1>
          </div>
          <Button
            size="small"
            colorType="ghost"
            disabled={data.contractTemplateDetails.templateStatus === "TEMP"}
            onClick={handleDownloadExcel}
          >
            템플릿 데이터 받기
          </Button>
        </TemplateDetailHeader>
        <TemplateDetailLayout>
          <div
            style={{
              flexShrink: 0,
            }}
          >
            <TemplateInfo
              createAt={data.contractTemplateDetails.createdAt}
              createdUserName={data.contractTemplateDetails.userName}
              email={data.contractTemplateDetails.userEmail}
              status={data.contractTemplateDetails.templateStatus}
              pageCount={numPages}
              receiverCnt={data.contractTemplateDetails.receiverCnt}
            />
          </div>

          <PDFLayout ref={ref}>
            <Document
              file={data.contractTemplateDetails.templateFile}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (_, index) => (
                <TemplateMainPdfItem
                  rootRef={ref}
                  key={`pdf_main_page_${index + 1}`}
                  index={index + 1}
                />
              ))}
            </Document>
          </PDFLayout>
          <PDFLayout
            style={{
              flexShrink: 0,
            }}
          >
            <Document file={data.contractTemplateDetails.templateFile}>
              {Array.from(new Array(numPages), (_, index) => (
                <TemplateListPdfItem
                  key={`pdf_page_${index + 1}`}
                  index={index + 1}
                />
              ))}
            </Document>
          </PDFLayout>
        </TemplateDetailLayout>
      </div>
    </BoundaryInViewProvider>
  );
};

export default TemplateDetailPage;

const TemplateDetailLayout = styled(Board)`
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  max-height: 102.4rem;
`;

const TemplateDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  > div {
    display: flex;
    align-items: center;
  }
`;

const PDFLayout = styled.div`
  max-height: calc(100vh - var(--header-height) - 10rem);
  overflow-y: auto;

  .pdf-document {
    border-radius: 2rem;
    margin: 1rem;
    overflow: hidden;
    width: 18rem;

    display: flex;
    justify-content: center;
  }

  .isActive {
    border: 1px solid var(--grey-700);
  }
`;
