import Button from "components/Common/Button/Button";
import { PlanItemContainer } from "pages/setting/payment/dashboard/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import RatePlanItemContentBox from "./RatePlanItemContentBox";

interface RatePlanItemContentProps {
  plan: {
    planName: string;
    planPrice: number;
  };
  discountPlan?: number;
  discountPlanRate?: number;
}

const RatePlanItemContent = ({
  plan,
  discountPlan,
  discountPlanRate,
}: RatePlanItemContentProps) => {
  const navigate = useNavigate();

  return (
    <PlanItemContainer>
      <RatePlanItemContentBox
        plan={plan}
        discountPlan={discountPlan}
        discountPlanRate={discountPlanRate}
      />

      <Button
        colorType={plan.planName === "Premium" ? "black" : "primary"}
        size="large"
        onClick={() =>
          navigate(`/setting/payment/billing?plan=${plan.planName}`)
        }
      >
        월 결제로 구매
      </Button>
    </PlanItemContainer>
  );
};

export default RatePlanItemContent;
