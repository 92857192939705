import { useQueries } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useUserDetailQuery = () => {
  const result = useQueries({
    queries: [{ ...queries.user.detail }, { ...queries.user.extraDetail }],
  });

  const data =
    result[0].data && result[1].data
      ? { ...result[0].data.userInfo, ...result[1].data.userExtraInfo }
      : undefined;

  return {
    data,
    isLoading: result.some((query) => query.isLoading),
    isError: result.some((query) => query.isError),
    refetch: () => {
      result[0].refetch();
      result[1].refetch();
    },
  };
};

export default useUserDetailQuery;
