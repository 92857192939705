import {
  GetInquiriesAllTypesResponse,
  GetInquiriesQnasResponse,
  PostAppInquiriesRequest,
} from "models/Inquiries";
import getAPIResponseData from "utils/getAPIResponse";

export const getInquiriesAllTypes = () => {
  return getAPIResponseData<GetInquiriesAllTypesResponse[]>({
    url: "/inquiries/all-types",
    method: "GET",
  });
};

export const postInquiries = (data: PostAppInquiriesRequest) => {
  return getAPIResponseData({
    url: "/inquiries",
    method: "POST",
    data: data,
  });
};

export const getInquiriesQnas = (search: string) => {
  const url =
    search !== "" && search.length > 1
      ? `/inquiries/qnas/search?keyword=${search}`
      : "/inquiries/qnas";
  return getAPIResponseData<GetInquiriesQnasResponse[]>({
    url: url,
    method: "GET",
  });
};
