interface PlanType {
  [key: string]: string;
}

export const PLAN: PlanType = {
  Free: "다양한 기능을 30일 동안 마음껏 이용해 보세요",
  Basic: "월 5건 이하의 계약 체결이 필요한 개인 또는 1인 사업자용 요금제",
  Standard: "5명 내외의 인원이 계약・관리를 진행하는 기업용 요금제",
  Premium: "Standard 전 기능 외에 간인 서비스",
};

type BottomTextItemType = {
  text?: string;
  gray?: string;
  bold?: string;
  icon?: boolean;
};

type BottomTextType = {
  [key: string]: BottomTextItemType[];
};

export const PLAN_BOTTOM_TEXT: BottomTextType = {
  Free: [
    {
      text: "문서 100건",
      gray: "(무료체험 기간 한정)",
    },
    {
      text: `카카오톡 알림톡/SMS 알림 무제한`,
    },
    {
      text: "일괄작성・대량전송",
    },
    {
      text: "URL로 문서 작성",
    },
    {
      text: "그룹 및 권한 설정",
    },
    {
      text: "API/Webhook 제공",
    },
  ],
  Basic: [
    {
      text: "개인용",
    },
    {
      text: "서명 요청 월5건",
    },
    {
      text: "템플릿",
      bold: "1개",
    },
    {
      text: "문서당",
      bold: "최대 2명 서명",
    },
    {
      text: "문서당 첨부파일",
      bold: "3개",
    },
    {
      text: "실시간 고객 지원",
    },
  ],
  Standard: [
    {
      text: "이용 계정",
      bold: "3개 이상",
      icon: true,
    },
    {
      text: "서명 요청 연",
      bold: "360건",
      icon: true,
    },
    {
      text: "템플릿",
      bold: "무제한",
      icon: true,
    },
    {
      bold: "대량전송",
      icon: true,
    },
    {
      bold: "링크 서명",
      icon: true,
    },
    {
      text: "문서당",
      bold: "최대 10명 서명",
      icon: true,
    },
    {
      text: "문서당 첨부파일",
      bold: "10개",
      icon: true,
    },
    {
      bold: "개인 연락처",
      icon: true,
    },
    {
      bold: "계약 리마인드",
      icon: true,
    },
    {
      bold: "문서 입력값 추출",
      icon: true,
    },
    {
      bold: "감사 로그",
      icon: true,
    },
    {
      text: "실시간 고객 지원",
      icon: true,
    },
  ],
  Premium: [
    {
      text: "이용 계정",
      bold: "3개 이상",
      icon: true,
    },
    {
      text: "서명 요청 연",
      bold: "360건",
      icon: true,
    },
    {
      text: "간인 서명 요청 연",
      bold: "24건",
      icon: true,
    },
    {
      text: "템플릿",
      bold: "무제한",
      icon: true,
    },
    {
      bold: "대량전송",
      icon: true,
    },
    {
      bold: "링크 서명",
      icon: true,
    },
    {
      text: "문서당",
      bold: "최대 10명 서명",
      icon: true,
    },
    {
      text: "문서당 첨부파일",
      bold: "10개",
      icon: true,
    },
    {
      bold: "개인 연락처",
      icon: true,
    },
    {
      bold: "계약 리마인드",
      icon: true,
    },
    {
      bold: "문서 입력값 추출",
      icon: true,
    },
    {
      bold: "감사 로그",
      icon: true,
    },
    {
      bold: "간인 서비스",
      icon: true,
    },
    {
      text: "실시간 고객 지원",
      icon: true,
    },
  ],
};
