import styled from "styled-components";

export const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 114rem;
  gap: 11rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  .faqItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5.4rem;
  }
`;

export const FaqItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  cursor: pointer;
  padding-bottom: 7.4rem;
  border-bottom: 1px solid var(--grey-300);

  &:last-child {
    border: none;
  }

  .faqTitleContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .faqTitle {
      color: var(--Text-Color-Text-Default);
      font-size: 2.2rem;
      font-weight: 600;
      line-height: 3rem;
      letter-spacing: -0.66px;
    }
  }

  .faqContent {
    color: var(--grey-600);
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: -0.54px;
  }
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--grey-400);

  .inputContainer {
    align-items: center;
    display: flex;
    gap: 1.6rem;

    .searchIcon {
      width: 4rem;
      height: 4rem;
    }

    & > input {
      background-color: transparent;
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 4.6rem;
      letter-spacing: -0.96px;
      color: #171d23;
      height: 4.6rem;
      width: 100%;

      ::placeholder {
        color: var(--grey-400);
      }
    }
  }
`;
