import { Body2Regular } from "components/TextStyle";
import styled from "styled-components";

export const TableLayout = styled.div`
  background-color: var(--bg-white);
  border-radius: 1rem;
`;

export const TableContainer = styled.table<{ gridTemplateColumns: string }>`
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns
      .split(" ")
      .map((col) => `minmax(0, ${col})`)
      .join(" ")};
`;

export const TableHeader = styled.thead`
  display: contents;
`;

export const TableBody = styled.tbody`
  display: contents;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid var(--grey-300);
  background: var(--grey-100);
  display: contents;
`;

export const TableCell = styled.td`
  padding: 1.7rem;
  background-color: var(--bg-white);
  align-self: center;
`;

export const TableHeadCell = styled.th`
  padding: 1.7rem;
  background-color: var(--bg-dark);
  white-space: nowrap;
  color: var(--text-secondary);
`;

export const CellEllipsis = styled(Body2Regular)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
