import styled from "styled-components";

export const Header1 = styled.div`
  font-weight: var(--bold);
  font-size: 2.4rem;
  line-height: 3.6rem;
`;

export const Header2 = styled.div`
  font-weight: var(--bold);
  font-size: 2rem;
  line-height: 2.8rem;
`;

export const Title1 = styled.div`
  font-weight: var(--semi-bold);
  font-size: 2rem;
  line-height: 2.8rem;
`;

export const SubTitle = styled.div`
  font-weight: var(--semi-bold);
  font-size: 1.8rem;
  line-height: 2.6rem;
`;

const Body1 = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const Body1SemiBold = styled(Body1)`
  font-weight: var(--semi-bold);
`;

export const Body1Medium = styled(Body1)`
  font-weight: var(--medium);
`;

export const Body1Regular = styled(Body1)`
  font-weight: var(--regular);
`;

const Body2 = styled.div`
  font-size: 1.5rem;
  line-height: 2.2rem;
`;

export const Body2SemiBold = styled(Body2)`
  font-weight: var(--semi-bold);
`;

export const Body2Medium = styled(Body2)`
  font-weight: var(--medium);
`;

export const Body2Regular = styled(Body2)`
  font-weight: var(--regular);
`;

export const Body3SemiBold = styled.div`
  font-weight: var(--semi-bold);
  font-size: 1.5rem;
  line-height: 2.2rem;
`;

export const Body3Regular = styled.div`
  font-weight: var(--regular);
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const CaptionRegular = styled.div`
  font-size: 1.2rem;
  font-weight: var(--regular);
  line-height: 2rem; /* 166.667% */
  letter-spacing: -0.6px;
`;
