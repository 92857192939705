import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getContactGroupsAll,
  getContactGroupsContactsAll,
  getContactSearch,
} from "apis/contact";

export const contactQueryKey = createQueryKeys("contact", {
  list: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => getContactGroupsContactsAll(organizationUuid),
  }),
  groupList: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => getContactGroupsAll(organizationUuid),
  }),
  search: ({
    organizationUuid,
    name,
  }: {
    organizationUuid?: string;
    name?: string;
  }) => ({
    queryKey: [organizationUuid, name],
    queryFn: () =>
      getContactSearch({
        organizationUuid,
        name: name,
      }),
  }),
});
