import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

interface ActiveSectionContextType {
  activeSection: string;
  setActiveSection: (id: string) => void;
}

export const ActiveSectionContext = createContext<
  ActiveSectionContextType | undefined
>(undefined);

const BoundaryInViewProvider = ({ children }: PropsWithChildren) => {
  const [activeSection, setActiveSection] = useState("");
  return (
    <ActiveSectionContext.Provider value={{ activeSection, setActiveSection }}>
      {children}
    </ActiveSectionContext.Provider>
  );
};

export default BoundaryInViewProvider;

export const useBoundaryInView = () => {
  const context = useContext(ActiveSectionContext);
  if (!context) {
    throw new Error("Cannot find BoundaryInViewProvider");
  }

  const { activeSection, setActiveSection } = context;

  return { activeSection, setActiveSection };
};
