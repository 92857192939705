import { useMutation } from "@tanstack/react-query";
import { postUserLogout } from "apis/user";
import { JWT_REFRESH_KEY } from "config/constant";
import useResetUser from "hooks/recoil/useResetUser";
import { useNavigate } from "react-router-dom";

const useSignOutMutation = () => {
  const refreshJwt = window.localStorage.getItem(JWT_REFRESH_KEY);
  const navigate = useNavigate();
  const resetUser = useResetUser();

  const mutation = useMutation({
    mutationFn: () => postUserLogout({ refreshJwt: refreshJwt ?? "" }),
    onSuccess: () => {
      resetUser();
      navigate("/");
    },
  });

  return mutation;
};

export default useSignOutMutation;
