import { useCallback, useRef } from "react";

function useDebounce(delay: number): (callback: () => void) => void {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback(
    (callback: () => void) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(callback, delay);
    },
    [delay]
  );

  return debouncedFunction;
}

export default useDebounce;
