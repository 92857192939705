import React, { ChangeEvent, forwardRef } from "react";
import styled from "styled-components";
import Selected from "assets/common/checkbox/icon_checkbox_selected.svg";
import Unselected from "assets/common/checkbox/icon_checkbox_unselected.svg";
import Disabled from "assets/common/checkbox/icon_checkbox_disabled.svg";
import DisabledSelected from "assets/common/checkbox/icon_checkbox_disabled_selected.svg";

interface CheckBoxProps {
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  value?: string;
}

const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ checked, onChange, isDisabled = false, value, ...args }, ref) => {
    return (
      <CheckBoxInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
        value={value}
        ref={ref}
        onClick={(e) => e.stopPropagation()}
        {...args}
      />
    );
  }
);

CheckBox.displayName = "CheckBox";

export default CheckBox;

export const CheckBoxInput = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  appearance: none;
  background-image: url(${Unselected});
  margin: 0;
  cursor: pointer;

  &:disabled {
    background-image: url(${Disabled});

    &:checked {
      background-image: url(${DisabledSelected});
    }
  }

  &:checked {
    background-image: url(${Selected});
  }
`;
