import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getNoticesById, getNoticesList } from "apis/notice";
import { Notice } from "models/Notice";

export const noticeQueryKey = createQueryKeys("notice", {
  list: {
    queryKey: null,
    queryFn: getNoticesList,
  },
  detail: (id: Notice["id"]) => ({
    queryKey: [id],
    queryFn: () => getNoticesById({ id }),
  }),
});
