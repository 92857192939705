import styled from "styled-components";

export const Header = styled.header`
  background-color: var(--bg-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  border-bottom: 1px solid var(--grey-300);
  background: var(--bg-white);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  height: 6.8rem;
  box-sizing: border-box;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  gap: 9.2rem;
  margin-right: 3rem;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    background-color: var(--grey-300);
    width: 0.1rem;
    height: 6.8rem;
  }
`;

export const LabelContainer = styled.div`
  flex-grow: 1;
`;

export const MenuContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  .association {
    flex-grow: 1;
    display: flex;
    gap: 1rem;
    white-space: nowrap;
  }

  .line {
    background-color: var(--grey-300);
    width: 0.1rem;
    height: 1.2rem;
  }

  .logoutContainer {
    display: flex;
    gap: 0.2rem;
    cursor: pointer;
    align-items: center;

    .logoutText {
      color: var(--grey-600);
    }
  }
`;
