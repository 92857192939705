import getAPIResponseData from "utils/getAPIResponse";
import {
  DeleteContactBatchReq,
  GetContactGroupsAllRes,
  GetContactGroupsContactsAllRes,
  PatchContactGroupsReq,
  PatchContactReq,
  PostContactBatchReq,
  PostContactGroupsReq,
  PostContactReq,
} from "../models/Contact";

export const getContactGroupsContactsAll = (organizationUuid?: string) => {
  return getAPIResponseData<GetContactGroupsContactsAllRes[]>({
    url: `/easy-setup/contact/groups/contacts/all/${organizationUuid}`,
    method: "GET",
  });
};

export const postContactGroups = (data: PostContactGroupsReq) => {
  return getAPIResponseData({
    url: "/easy-setup/contact/groups",
    method: "POST",
    data: data,
  });
};

export const postContact = (data: PostContactReq) => {
  return getAPIResponseData({
    url: "/easy-setup/contact",
    method: "POST",
    data: data,
  });
};

export const getContactGroupsAll = (organizationUuid?: string) => {
  return getAPIResponseData<GetContactGroupsAllRes[]>({
    url: `/easy-setup/contact/groups/all/${organizationUuid}`,
    method: "GET",
  });
};

export const patchContact = ({
  organizationContactUuid,
  data,
}: {
  organizationContactUuid: string;
  data: PatchContactReq;
}) => {
  return getAPIResponseData({
    url: `/easy-setup/contact/${organizationContactUuid}`,
    method: "PATCH",
    data,
  });
};

export const deleteContact = (data: DeleteContactBatchReq) => {
  return getAPIResponseData({
    url: `/easy-setup/contact/batch`,
    method: "DELETE",
    data,
  });
};

export const patchContactGroups = ({
  organizationContactGroupUuid,
  data,
}: {
  organizationContactGroupUuid: string;
  data: PatchContactGroupsReq;
}) => {
  return getAPIResponseData({
    url: `/easy-setup/contact/groups/${organizationContactGroupUuid}`,
    method: "PATCH",
    data,
  });
};

export const postContactBatch = (data: PostContactBatchReq) => {
  return getAPIResponseData({
    url: "/easy-setup/contact/batch",
    method: "POST",
    data,
  });
};

export const getContactSearch = ({
  organizationUuid,
  name,
}: {
  organizationUuid?: string;
  name?: string;
}) => {
  let params = {};
  if (name) params = { name: name };

  return getAPIResponseData<GetContactGroupsContactsAllRes[]>({
    url: `/easy-setup/contact/search/${organizationUuid}`,
    method: "GET",
    params: params,
  });
};
