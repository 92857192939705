import React from "react";
import { ReactComponent as InformationIcon } from "assets/common/20px/icon_information_small.svg";
import { Body1Regular, Body3Regular } from "components/TextStyle";
import styled from "styled-components";

interface RatePlanItemContentProps {
  plan: {
    planName: string;
    planPrice: number;
  };
  discountPlan?: number;
  discountPlanRate?: number;
}

const RatePlanItemContentBox = ({
  plan,
  discountPlan,
  discountPlanRate,
}: RatePlanItemContentProps) => {
  return (
    <PlanContainer>
      <div className="planCaptionContainer">
        <div className="caption">할인 미적용</div>
        <InformationIcon width={20} height={20} stroke="#666F7B" />
      </div>

      <div className="planContentWrapper">
        {discountPlan && (
          <div className="discountPlanContainer">
            <div className="discountPlanRate">-{discountPlanRate}%</div>
            <div className="discountPlan">
              {plan.planPrice?.toLocaleString("kr")}
            </div>
          </div>
        )}

        <div className="planContentContainer">
          <div className="planContentItemContainer">
            <div className="plan">
              {discountPlan
                ? discountPlan?.toLocaleString("kr")
                : plan.planPrice?.toLocaleString("kr")}
            </div>
            <Body1Regular>원</Body1Regular>
          </div>

          <Body3Regular className="unit">/ 월 *부가세 포함</Body3Regular>
        </div>
      </div>
    </PlanContainer>
  );
};

export default RatePlanItemContentBox;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  .planCaptionContainer {
    display: flex;
    align-items: center;

    .caption {
      color: var(--grey-600);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.6px;
    }
  }

  .planContentWrapper {
    display: flex;
    flex-direction: column;
  }

  .planContentContainer {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    flex-wrap: wrap;

    .planContentItemContainer {
      display: flex;
      gap: 0.3rem;
      align-items: center;

      .plan {
        color: var(--text-default);
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 3.8rem;
        letter-spacing: -0.84px;
      }
    }

    .unit {
      margin-left: 0.5rem;
      color: var(--grey-600);
    }
  }

  .discountPlanContainer {
    display: flex;
    gap: 0.4rem;
    align-items: center;

    .discountPlanRate {
      color: var(--red-400);
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.6rem;
      letter-spacing: -0.72px;
    }

    .discountPlan {
      color: var(--grey-500);
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.6rem;
      letter-spacing: -0.72px;
      text-decoration: line-through;
    }
  }
`;
