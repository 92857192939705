import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getNotificationToday } from "apis/notification";

export const notificationQueryKey = createQueryKeys("notification", {
  list: (size: number) => [size],
  today: {
    queryKey: null,
    queryFn: getNotificationToday,
  },
});
