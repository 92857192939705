import styled from "styled-components";

export const LNBContainer = styled.div`
  width: 22rem;
  padding: 3rem 1rem;
  background-color: var(--bg-white);
  box-sizing: border-box;

  flex-shrink: 0;
`;

export const LNBItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LNBItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const LabelContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  gap: 0.8rem;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.8rem;
  align-items: center;
  background-color: ${(props) =>
    props.$isSelected ? "var(--primary-100)" : "var(--bg-white)"};

  .menuIcon {
    width: 2rem;
    height: 2rem;
  }

  .label {
    color: ${(props) =>
      props.$isSelected ? "var(--primary-600)" : "var(--grey-600)"};
  }

  &:hover {
    background-color: var(--primary-100);

    .label {
      color: var(--primary-600);
    }
  }
`;

export const LNBSubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const LNBSubItemContainer = styled(LabelContainer)`
  gap: 0.4rem;
  padding: 1rem 3rem;
  background-color: ${(props) =>
    props.$isSelected ? "var(--grey-100) !important" : "var(--bg-white)"};

  .label {
    color: ${(props) =>
      props.$isSelected ? "var(--primary-600) !important" : "var(--grey-600)"};
  }

  &:hover {
    background-color: var(--bg-white);

    .label {
      color: var(--grey-900);
    }
  }
`;
