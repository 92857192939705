import getAPIResponseData from "utils/getAPIResponse";

export const getDropdownListAll = (organizationId?: string) => {
  return getAPIResponseData<GetDropdownListAllRes[]>({
    url: `/easy-setup/dropdown-list/all/${organizationId}`,
    method: "GET",
  });
};

export const postDropdownList = (data: PostDropdownListReq) => {
  return getAPIResponseData({
    url: "/easy-setup/dropdown-list",
    method: "POST",
    data: data,
  });
};

export const patchDropdownList = (data: PatchDropdownListReq) => {
  return getAPIResponseData({
    url: `/easy-setup/dropdown-list/${data.dropdownListUuid}`,
    method: "PATCH",
    data: {
      dropdownListTitle: data.dropdownListTitle,
      dropdownListItems: data.dropdownListItems,
    },
  });
};

export const getDropdownListDetail = (dropdownListUuid?: string) => {
  return getAPIResponseData<DropdownListItemData>({
    url: `/easy-setup/dropdown-list/detail/${dropdownListUuid}`,
    method: "GET",
  });
};

export const deleteDropdownList = ({
  organizationUuid,
  dropdownListUuids,
}: {
  organizationUuid: string;
  dropdownListUuids: string[];
}) => {
  return getAPIResponseData({
    url: `/easy-setup/dropdown-list/batch`,
    method: "DELETE",
    data: { organizationUuid, dropdownListUuids },
  });
};
