import request from "apis/core";
import { AxiosRequestConfig } from "axios";

const getAPIResponseData = async <T, D = T>(option: AxiosRequestConfig<D>) => {
  try {
    const data = await request<T>(option);
    return data;
  } catch (e) {
    process.env.NODE_ENV === "development" && console.error(e);
    throw e;
  }
};

export default getAPIResponseData;
