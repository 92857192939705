import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useOrganizationListQuery = () => {
  return useQuery({
    ...queries.organization.list,
  });
};

export default useOrganizationListQuery;
