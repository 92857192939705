import { Header1 } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "assets/common/24px/icon_left_default.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NOTICE_PATH = {
  DASHBOARD: "/notice/dashboard",
  GUIDE: "/notice/guide",
};

const NoticeNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <NoticeNavigationLayout>
      <LeftArrowIcon onClick={() => navigate(-1)} />
      <NoticeDashboardTitle
        isSelected={pathname.includes(NOTICE_PATH.DASHBOARD)}
      >
        <Link to={NOTICE_PATH.DASHBOARD}>공지사항</Link>
      </NoticeDashboardTitle>
      <hr />
      <NoticeDashboardTitle isSelected={pathname.includes(NOTICE_PATH.GUIDE)}>
        <Link to={NOTICE_PATH.GUIDE}>이용방법</Link>
      </NoticeDashboardTitle>
    </NoticeNavigationLayout>
  );
};

export default NoticeNavigation;

const NoticeNavigationLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.8rem;
  margin-bottom: 4.4rem;

  svg {
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
  }

  hr {
    width: 1px;
    height: 1.2rem;
    outline: none;
    border: none;
    background-color: var(--grey-300);
    margin: 0;
  }
`;

const NoticeDashboardTitle = styled(Header1)<{
  isSelected?: boolean;
}>`
  display: inline-block;
  color: ${({ isSelected }) =>
    isSelected ? "inherit" : "var(--text-tertiary)"};
`;
