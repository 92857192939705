import { AxiosResponse } from "axios";
import {
  DeleteTemplateByIdFavoritesByIdRequest,
  DeleteTemplateByIdRequest,
  DeleteTemplateByIdRequestData,
  GetTemplateByIdByIdRequest,
  GetTemplateByIdByIdResponse,
  GetTemplateDataExcelRequest,
  GetTemplateDetailInfoRequest,
  GetTemplateDetailInfoResponse,
  GetTemplateDetailsByIdRequest,
  GetTemplateDetailsByIdResponse,
  GetTemplateListByIdRequest,
  GetTemplateListByIdResponse,
  GetTemplatePresignedUrlRequest,
  PostTemplateByIdFavoriteByIdRequest,
  PostTemplateRequest,
  PutTemplateRequest,
} from "models/Template";
import getAPIResponseData from "utils/getAPIResponse";

export const deleteTemplateByIdFavoriteById = ({
  organizationId,
  templateId,
}: DeleteTemplateByIdFavoritesByIdRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationId}/favorite/${templateId}`,
    method: "DELETE",
  });
};

export const deleteTemplateById = ({
  organizationId,
  templateIdList,
}: DeleteTemplateByIdRequest) => {
  return getAPIResponseData<DeleteTemplateByIdRequestData>({
    url: `/templates/${organizationId}`,
    method: "DELETE",
    data: {
      templateUuidList: templateIdList.map((templateId) => ({
        templateUuid: templateId,
      })),
    },
  });
};

export const getTemplateByIdById = ({
  organizationId,
  templateId,
}: GetTemplateByIdByIdRequest) => {
  return getAPIResponseData<
    GetTemplateByIdByIdRequest,
    GetTemplateByIdByIdResponse
  >({
    url: `/templates/${organizationId}/${templateId}`,
    method: "GET",
  });
};

export const getTemplatePresignedUrl = ({
  organizationId,
}: GetTemplatePresignedUrlRequest) => {
  return getAPIResponseData({
    url: `/templates/presigned-url`,
    method: "GET",
    params: {
      uuid: organizationId,
    },
  });
};

export const getTemplateListById = ({
  organizationId,
  options,
}: GetTemplateListByIdRequest) => {
  return getAPIResponseData<GetTemplateListByIdResponse>({
    url: `/templates/list/${organizationId}`,
    method: "GET",
    params: {
      total: options?.total,
      createdUserName: options?.createdUserName,
      templateName: options?.templateName,
      onlyFavoriteTemplate: options?.onlyFavoriteTemplate ?? false,
      onlyMyTemplate: options?.onlyMyTemplate ?? false,
      page: options?.page ?? 0,
      size: options?.size ?? 20,
    },
  });
};

export const getTemplateDetailInfo = ({
  organizationId,
  templateId,
}: GetTemplateDetailInfoRequest) => {
  return getAPIResponseData<GetTemplateDetailInfoResponse>({
    url: `/templates/${organizationId}/${templateId}/details-info`,
    method: "GET",
  });
};

export const getTemplateDataExcel = ({
  organizationId,
  templateId,
}: GetTemplateDataExcelRequest) => {
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/templates/${organizationId}/${templateId}/data-excel`,
    responseType: "arraybuffer",
    headers: {
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    method: "GET",
  });
};

export const postTemplateByIdFavoriteById = ({
  organizationId,
  templateId,
}: PostTemplateByIdFavoriteByIdRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationId}/favorite/${templateId}`,
    method: "POST",
  });
};

export const postTemplate = ({
  organizationUuid,
  data,
}: PostTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}`,
    method: "POST",
    data,
  });
};

export const getTemplateDetailsById = ({
  organizationUuid,
  templateUuid,
}: GetTemplateDetailsByIdRequest) => {
  return getAPIResponseData<GetTemplateDetailsByIdResponse>({
    url: `/templates/${organizationUuid}/${templateUuid}/details`,
    method: "GET",
  });
};

export const putTemplate = ({
  organizationUuid,
  contractTemplateUuid,
  data,
}: PutTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}/${contractTemplateUuid}`,
    method: "PUT",
    data,
  });
};
