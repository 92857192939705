import Button from "components/Common/Button/Button";
import {
  DropDownContent,
  DropDownItem,
  DropdownItemType,
  DropDownRoot,
  DropDownTrigger,
} from "components/Common/Dropdown/DropdownMenu";
import React, { MouseEvent, MouseEventHandler, useState } from "react";

import { ReactComponent as UpIcon } from "assets/common/24px/icon_up.svg";
import { ReactComponent as DownIcon } from "assets/common/24px/icon_down_default.svg";
import { useNavigate } from "react-router-dom";

interface DashboardRowSendButtonProps {
  list: DropdownItemType[];
  id: string;
  isDisabled?: boolean;
}

const DashboardRowSendButton = ({
  list,
  id,
  isDisabled,
}: DashboardRowSendButtonProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenChange = (open: boolean) => {
    isDisabled || setIsOpen(open);
  };

  const handleItemClick = (
    event: MouseEvent<HTMLDivElement>,
    onClick?: MouseEventHandler<HTMLDivElement>
  ) => {
    event.stopPropagation(); // 이벤트 전파 중지
    if (onClick) {
      onClick(event); // 원래 항목에 설정된 onClick 함수 실행
    }
  };

  return (
    <Button
      colorType="tertiary"
      size="small"
      onClick={() => navigate(`/template/send/${id}/config/single`)}
      isDisabled={isDisabled}
    >
      전송하기
      <DropDownRoot open={isOpen} onOpenChange={handleOpenChange}>
        <DropDownTrigger>
          {isOpen ? <UpIcon /> : <DownIcon opacity={isDisabled ? 0.3 : 1} />}
        </DropDownTrigger>
        <DropDownContent>
          {list.map(({ label, onClick, ...args }) => {
            return (
              <DropDownItem
                key={label}
                onClick={(e) => handleItemClick(e, onClick)}
                {...args}
              >
                {label}
              </DropDownItem>
            );
          })}
        </DropDownContent>
      </DropDownRoot>
    </Button>
  );
};

export default DashboardRowSendButton;
