import Button from "components/Common/Button/Button";
import { KonvaEventObject } from "konva/lib/Node";
import React, { useRef, useState } from "react";
import { Layer, Line, Stage } from "react-konva";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ReactComponent as PenIcon } from "assets/common/30px/icon_edit_pen.svg";
import styled from "styled-components";
import { Stage as StageRefType } from "konva/lib/Stage";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import resizeImage from "utils/resizeImage";
import konvaToImage from "utils/konvaToImage";

interface DrawLine {
  points: number[];
  lineWidth: number;
}

interface DrawSignContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const DrawSignContent = ({
  closeModal,
  signMode,
  sign,
}: DrawSignContentProps) => {
  const { mutate } = useSignaturesMutation();
  const [lines, setLines] = useState<DrawLine[]>([]);
  const [lineWidth, setLineWidth] = useState(3);

  const isDrawing = useRef(false);
  const stageRef = useRef<StageRefType | null>(null);

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    isDrawing.current = true;
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    setLines([...lines, { points: [pos.x, pos.y], lineWidth: lineWidth }]);
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!isDrawing.current) {
      return;
    }
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    const lastLine = lines[lines.length - 1];

    lastLine.points = lastLine.points.concat([pos.x, pos.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleCleanClick = () => {
    setLines([]);
  };

  const handleChangeLineWidth = (lineWidth: number) => {
    setLineWidth(lineWidth);
  };

  const handleSaveClick = async () => {
    if (!stageRef.current) return;

    const scaleSize = resizeImage(
      {
        width: stageRef.current.width(),
        height: stageRef.current.height(),
      },
      {
        width: 200,
        height: 70,
      }
    );

    const file = await konvaToImage(stageRef.current, {
      width: 200,
      height: 70,
      ...scaleSize,
    });

    if (signMode && sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  return (
    <>
      <Stage
        ref={stageRef}
        width={1000}
        height={350}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{
          backgroundColor: "#F2F7FF",
        }}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="#000000"
              strokeWidth={line.lineWidth}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={"source-over"}
            />
          ))}
        </Layer>
      </Stage>
      <DrawFooter>
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger>
              <PenIcon />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <ToolTipContent sideOffset={5} align="end">
                {Array.from({ length: 5 }).map((_, index) => (
                  <LineWidthButton
                    key={index}
                    lineWidth={index + 1}
                    onClick={() => handleChangeLineWidth(index + 1)}
                    isSelected={lineWidth === index + 1}
                  >
                    <hr />
                  </LineWidthButton>
                ))}
                <Tooltip.Arrow className="TooltipArrow" />
              </ToolTipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <Button colorType="tertiary" size="medium" onClick={handleCleanClick}>
          지우기
        </Button>
      </DrawFooter>
      <ModalFooter>
        <Button colorType="tertiary" size="large" onClick={closeModal}>
          취소
        </Button>
        <Button colorType="primary" size="large" onClick={handleSaveClick}>
          확인
        </Button>
        {/* TODO: api 요청후 modal을 닫음 */}
      </ModalFooter>
    </>
  );
};

export default DrawSignContent;

const DrawFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 2rem;
`;

const ToolTipContent = styled(Tooltip.Content)`
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin: auto;

  .TooltipArrow {
    fill: rgba(0, 0, 0, 0.6);
  }
`;

const LineWidthButton = styled.button<{
  lineWidth: number;
  isSelected: boolean;
}>`
  background-color: ${({ isSelected }) =>
    isSelected ? "rgba(255, 255, 255, 0.20)" : "transparent"};
  border-radius: 0.4rem;
  padding: 0.4rem 1rem;
  > hr {
    margin: 0;
    width: ${({ lineWidth }) => `${lineWidth}px`};
    height: 1.5rem;
    border-radius: 0.4rem;
    background-color: #ffffff;
    transform: rotate(-45deg);
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  > button {
    width: 24rem;
  }
`;
