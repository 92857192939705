import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getDropdownListAll, getDropdownListDetail } from "apis/dropdownList";

export const dropdownListQueryKey = createQueryKeys("dropwonList", {
  list: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => getDropdownListAll(organizationUuid),
  }),
  detail: (dropdownListUuid?: string) => ({
    queryKey: [dropdownListUuid],
    queryFn: () => getDropdownListDetail(dropdownListUuid),
  }),
});
