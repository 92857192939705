import { Organization } from "models/Organization";
import { OrganizationGroup } from "models/OrganizationGroup";
import { User } from "models/User";
import { atom, useRecoilState } from "recoil";

export const UserInfoState = atom<{
  uuid?: Pick<User, "uuid">["uuid"];
  organization?: Pick<Organization, "uuid">["uuid"];
  organizationGroup?: Pick<OrganizationGroup, "uuid">["uuid"];
}>({
  key: "useInfoState",
  default: {
    uuid: undefined,
    organization: undefined,
    organizationGroup: undefined,
  },
});

/**
 * @description 현재 사용자의 정보를 관리하는 전역 상태
 */
const useUserState = () => {
  const [state, setState] = useRecoilState(UserInfoState);

  return [state, setState] as const;
};

export default useUserState;
