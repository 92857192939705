import React, { useEffect, useMemo } from "react";
import { Header, LabelContainer, LogoContainer, MenuContainer } from "./styles";
import LogoIcon from "assets/common/icon_logo.svg";
import { Body1Medium, Body2Medium } from "components/TextStyle";
import { useLocation } from "react-router-dom";
import { MENU_ITEMS } from "components/LNB/MenuItemsConstant";
import { ReactComponent as LogoutIcon } from "assets/common/24px/icon_logout.svg";
import { Link } from "react-router-dom";
import Dropdown from "components/Common/Dropdown/Dropdown";
import NotificationDropDownMenu from "./NotificationDropDownMenu";
import UserDropDownMenu from "./UserDropDownMenu";
import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";
import useOrganizationGroupListQuery from "hooks/queries/organizationGroup/useOrganizationGroupListQuery";
import useUserState from "hooks/recoil/useUserState";
import useUserDetailQuery from "hooks/queries/user/useUserDetailQuery";
import useSignOutMutation from "hooks/mutations/auth/useSignOutMutation";

const AppHeader = ({ contract }: { contract?: boolean }) => {
  const { pathname } = useLocation();
  const [user, setUser] = useUserState();
  const { data } = useUserDetailQuery();
  const { mutate: signOutMutate } = useSignOutMutation();

  const { data: organizationListData } = useOrganizationListQuery();
  const organizationListDTO = useMemo(() => {
    return (
      organizationListData?.organizationList.map((organization) => ({
        label: organization.companyName,
        value: organization.organizationUuid,
      })) || []
    );
  }, [organizationListData]);

  const { data: organizationGroupListData } = useOrganizationGroupListQuery(
    user.organization
  );

  const organizationGroupListDTO = useMemo(() => {
    const baseList = organizationGroupListData?.organizationGroupList.map(
      (group) => ({
        label: group.groupName,
        value: group.organizationGroupUuid,
      })
    );

    return [{ label: "그룹 없음", value: "" }, ...(baseList ?? [])];
  }, [organizationGroupListData]);

  const title = MENU_ITEMS.find(
    (e) => pathname.split("/")[1] === e.to.split("/")[1]
  )?.label;

  useEffect(() => {
    const isAPIInit =
      data?.userUuid &&
      organizationListDTO.length > 0 &&
      organizationGroupListDTO.length > 0;

    const isUserInit = !user.uuid;

    if (isAPIInit && isUserInit) {
      setUser((prevUser) => ({
        ...prevUser,
        uuid: data?.userUuid,
        organization: organizationListDTO[0]?.value.toString(),
        organizationGroup:
          organizationGroupListDTO[0]?.value.toString() ?? "그룹 없음",
      }));
    }
  }, [data, organizationGroupListDTO, organizationListDTO, setUser, user.uuid]);

  return contract ? (
    <></>
  ) : (
    <Header>
      <LogoContainer>
        <Link to={"/dashboard"}>
          <img src={LogoIcon} alt="logoIcon" />
        </Link>
        <div className="line" />
      </LogoContainer>
      <LabelContainer>
        <Body1Medium>{title}</Body1Medium>
      </LabelContainer>

      <MenuContainer>
        <div className="association">
          <Dropdown
            dropdownList={organizationListDTO}
            selected={user.organization ?? ""}
            setSelected={(value: string) =>
              setUser((prevUser) => ({
                ...prevUser,
                organization: value.toString(),
                organizationGroup:
                  organizationGroupListDTO[0]?.value.toString() ?? "그룹 없음",
              }))
            }
            outline={false}
          />
          <Dropdown
            dropdownList={organizationGroupListDTO}
            selected={user.organizationGroup ?? ""}
            setSelected={(value: string) =>
              setUser((prevUser) => ({
                ...prevUser,
                organizationGroup: value.toString(),
              }))
            }
            outline={false}
          />
        </div>
        <NotificationDropDownMenu />
        <UserDropDownMenu />
        <div className="line" />
        <button className="logoutContainer" onClick={() => signOutMutate()}>
          <LogoutIcon stroke="#666F7B" />
          <Body2Medium className="logoutText">로그아웃</Body2Medium>
        </button>
      </MenuContainer>
    </Header>
  );
};

export default AppHeader;
