import { Header1 } from "components/TextStyle";
import styled from "styled-components";

const TitleTabLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.8rem;
  margin-bottom: 4.4rem;

  svg {
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
  }

  hr {
    width: 1px;
    height: 1.2rem;
    outline: none;
    border: none;
    background-color: var(--grey-300);
    margin: 0;
  }
`;

const TitleTabItem = styled(Header1)<{
  isSelected?: boolean;
}>`
  display: inline-block;
  color: ${({ isSelected }) =>
    isSelected ? "inherit" : "var(--text-tertiary)"};
`;

const TitleTab = {
  Layout: TitleTabLayout,
  Item: TitleTabItem,
};

export default TitleTab;
