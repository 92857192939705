import Button from "components/Common/Button/Button";
import DatePicker from "components/Common/DatePicker/DatePicker";
import ActionModal from "components/Common/Modal/ActionModal";
import { Body3Regular } from "components/TextStyle";
import React, { useState } from "react";
import styled from "styled-components";
import { Duration, sub } from "date-fns";

const DESCRIPTION = [
  <>
    기간을 지정하여, 선택한 템플릿의 전송 후 진행상태 및 발신자, <br /> 수신자가
    입력한 데이터를 엑셀 형식으로 일괄 추출할 수 있습니다.
  </>,
  "서명, 도장 데이터는 추출할 수 없습니다.",
  "열 데이터 순서는 임의 추출이며 순서를 지정할 수 없습니다.",
];

interface TemplateDataExportModalProps {
  closeModal: () => void;
}

const TemplateDataExportModal = ({
  closeModal,
}: TemplateDataExportModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const changeSubDate = (option: Duration) => {
    return () => {
      setStartDate(sub(new Date(), { ...option }));
      setEndDate(new Date());
    };
  };

  return (
    <ActionModal title="데이터 추출" handleModalClose={closeModal} widthFull>
      <Layout>
        <ul>
          {DESCRIPTION.map((desc, index) => (
            <li key={index}>
              <Body3Regular>{desc}</Body3Regular>
            </li>
          ))}
        </ul>
        <DatePickerLayout>
          <DatePicker
            selectedDate={startDate ?? undefined}
            onChange={handleStartDateChange}
          />
          <hr />
          <DatePicker
            selectedDate={endDate ?? undefined}
            onChange={handleEndDateChange}
          />
        </DatePickerLayout>
        <DateButtonLayout>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ days: 0 })}
          >
            오늘
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ weeks: 1 })}
          >
            1주일
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ months: 1 })}
          >
            1개월
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ months: 3 })}
          >
            3개월
          </Button>
        </DateButtonLayout>
      </Layout>
      <Footer>
        <Button colorType="ghost" size="large">
          취소
        </Button>
        <Button colorType="primary" size="large">
          추출하기
        </Button>
      </Footer>
    </ActionModal>
  );
};

export default TemplateDataExportModal;

const Layout = styled.div`
  li {
    list-style: outside;
    margin-left: 1rem;
  }
`;

const DatePickerLayout = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1.7rem;
  align-items: center;
  width: 38rem;
  hr {
    height: 1px;
    border: none;
    background-color: var(--stroke-dark);
    width: 1.2rem;
  }
`;

const DateButtonLayout = styled.div`
  margin-top: 1.2rem;
  display: flex;
  gap: 1.7rem;
  align-items: center;

  button {
    flex: 1;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: -2rem;
`;
