import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import useUserState from "hooks/recoil/useUserState";

const useContractRemainCreditsQuery = () => {
  const [user] = useUserState();
  const id = user.organization;
  return useQuery({
    ...queries.contract.organizationId(id)._ctx.remainCredits,
    enabled: !!id,
  });
};

export default useContractRemainCreditsQuery;
