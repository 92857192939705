import styled from "styled-components";

export const ToggleContainer = styled.button<{
  $isSelected: boolean;
}>`
  cursor: pointer;
  width: 4.8rem;
  height: 2rem;
  border-radius: 17.5px;
  background-color: ${(props) =>
    props.$isSelected ? "var(--primary-200)" : "var(--grey-300)"};
  position: relative;

  .toggleCircle {
    position: absolute;
    top: -2px;
    right: ${(props) => (props.$isSelected ? "0px" : "2.3rem")};
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: ${(props) =>
      props.$isSelected ? "var(--primary-400)" : "var(--grey-400)"};
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :disabled {
    background-color: var(--grey-200);
    cursor: default;

    .toggleCircle {
      background-color: var(--grey-300);
    }
  }
`;
