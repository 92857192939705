import { device } from "components/styles";
import { useEffect, useState } from "react";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    // 미디어 쿼리를 통해 매치되는 브레이크포인트 찾기
    const match = () => {
      if (window.matchMedia(device.xs).matches) {
        setBreakpoint("xs");
      } else if (window.matchMedia(device.sm).matches) {
        setBreakpoint("sm");
      } else if (window.matchMedia(device.md).matches) {
        setBreakpoint("md");
      } else if (window.matchMedia(device.lg).matches) {
        setBreakpoint("lg");
      } else {
        setBreakpoint("lg+"); // lg 이상의 넓은 화면을 위한 처리
      }
    };

    match(); // 초기 로드 시 브레이크포인트 설정
    window.addEventListener("resize", match); // 윈도우 사이즈 변경에 따라 업데이트

    return () => {
      window.removeEventListener("resize", match); // 클린업 함수
    };
  }, []);

  return breakpoint;
};
