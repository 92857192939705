import TitleTab from "components/Common/TitleTab/TitleTab";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const LANDING_PATH = {
  SUPPORT: "/support",
  FAQ: "/faq",
};

const LandingNavigation = () => {
  const { pathname } = useLocation();

  return (
    <TitleTab.Layout>
      <TitleTab.Item isSelected={pathname.includes(LANDING_PATH.SUPPORT)}>
        <Link to={LANDING_PATH.SUPPORT}>문의하기</Link>
      </TitleTab.Item>
      <TitleTab.Item isSelected={pathname.includes(LANDING_PATH.FAQ)}>
        <Link to={LANDING_PATH.FAQ}>자주묻는 질문</Link>
      </TitleTab.Item>
    </TitleTab.Layout>
  );
};

export default LandingNavigation;
