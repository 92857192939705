import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useBoundaryInView } from "./BoundaryInViewProvider";

interface BoundaryInViewProps {
  children: React.ReactNode;
  id: string;
  rootRef?: React.RefObject<HTMLDivElement>;
  top?: {
    offsetY: number;
  };
  bottom?: {
    offsetY: number;
  };
}

const BoundaryInView = ({ children, id, rootRef }: BoundaryInViewProps) => {
  const { setActiveSection } = useBoundaryInView();

  const { ref: topRef, inView: isTopInView } = useInView({
    threshold: 0,
    rootMargin: "0px 0px -80% 0px",
    root: rootRef?.current,
  });

  // 하단 감지기 - 요소가 화면에 들어오면 감지
  const { ref: bottomRef, inView: isBottomInView } = useInView({
    threshold: 0,
    root: rootRef?.current,
  });

  useEffect(() => {
    if (isTopInView) {
      setActiveSection(`#${id}`);
    }

    if (isBottomInView) {
      setActiveSection(`#${id}`);
    }
  }, [id, isBottomInView, isTopInView, setActiveSection]);

  return (
    <section
      id={id}
      style={{
        position: "relative",
        scrollMarginTop: "7rem",
      }}
    >
      <div
        ref={topRef}
        style={{
          position: "absolute",
          top: 0,
          height: "1px",
          width: "100%",
        }}
      />
      {children}
      <div
        ref={bottomRef}
        style={{
          position: "absolute",
          bottom: "30vh",
          height: "1px",
          width: "100%",
        }}
      />
    </section>
  );
};

export default BoundaryInView;
