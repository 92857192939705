interface GetYearRangeProps {
  startYear: number;
  endYear: number;
  step: number;
}

const getYearRange = ({ startYear, endYear, step }: GetYearRangeProps) => {
  const yearArray = [];
  for (let year = startYear; year <= endYear; year += step) {
    yearArray.push(year);
  }
  return yearArray;
};

export default getYearRange;
