import { Board } from "components/Common/Board/Board";
import Paging from "components/Common/Pagination/Pagination";
import DashboardHeader from "components/template/DashboardHeader";
import DashboardTable from "components/template/DashboardTable";
import { Header1 } from "components/TextStyle";
import useTemplateList from "hooks/queries/template/useTemplateList";
import useUserState from "hooks/recoil/useUserState";
import React, { useState } from "react";
import styled from "styled-components";

const TemplateDashboardPage = () => {
  const [checkedRow, setCheckedRow] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOptions, setSearchOptions] = useState({
    total: "",
    createdUserName: "",
    templateName: "",
  });
  const [user] = useUserState();

  const { data } = useTemplateList({
    organizationId: user.organization,
    options: {
      ...searchOptions,
      page: currentPage - 1,
      size: 10,
    },
  });

  if (!data) return;

  return (
    <div>
      <Header1>전체</Header1>
      <DashboardHeader
        checkedRow={checkedRow}
        setSearchOptions={setSearchOptions}
      />
      <Board>
        <DashboardTable
          checkedRow={checkedRow}
          setCheckedRow={setCheckedRow}
          templateList={data.templateList.contents}
        />
        <PaginationLayout>
          <Paging
            count={data.templateList.totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </PaginationLayout>
      </Board>
    </div>
  );
};

export default TemplateDashboardPage;

const PaginationLayout = styled.div`
  margin: 0 2rem auto 0rem;
  padding: 3rem 0rem 4rem 0;

  > nav {
    border: none;
    width: fit-content;
    margin-left: auto;
  }
`;
