import { useEffect, useState } from "react";

interface UseFontLoadProps {
  fontFamily: string;
  url: string;
  format: string;
}

const useFontLoad = (fonts: UseFontLoadProps[]) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const fontPromises = fonts.map((font) => {
      const fontFace = new FontFace(font.fontFamily, `url(${font.url})`, {
        style: "normal",
        weight: "normal",
      });

      return fontFace.load().then(() => {
        document.fonts.add(fontFace);
      });
    });

    Promise.all(fontPromises)
      .then(() => {
        setFontLoaded(true);
      })
      .catch((error) => {
        console.error("One or more fonts failed to load:", error);
        setFontLoaded(false);
      });
  }, [fonts]);

  return fontLoaded;
};

export default useFontLoad;
