import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import styled from "styled-components";
import { Body3Regular } from "components/TextStyle";

interface TooltipProps {
  side?: "top" | "right" | "bottom" | "left";
  children: React.ReactNode;
  description: React.ReactNode;
}

const Tooltip = ({ side, children, description }: TooltipProps) => {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <ToolTipContent side={side}>
            <Body3Regular>{description}</Body3Regular>
            <RadixTooltip.Arrow className="tooltip-arrow" />
          </ToolTipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

const ToolTipContent = styled(RadixTooltip.Content)`
  color: var(--bg-white);
  padding: 0.8rem 1.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.8rem;

  .tooltip-arrow {
    fill: rgba(0, 0, 0, 0.6);
  }
`;

export default Tooltip;
