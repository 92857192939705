import {
  GetTemplateStoreRequest,
  GetTemplateStoreResponse,
  PostTemplateStoreByIdRequest,
} from "models/templateStore";
import getAPIResponseData from "utils/getAPIResponse";

export const getTemplateStore = ({
  options,
}: {
  options: GetTemplateStoreRequest;
}) => {
  return getAPIResponseData<GetTemplateStoreResponse, GetTemplateStoreRequest>({
    url: "/template-stores",
    method: "GET",
    params: {
      page: options.page,
      size: options.size,
    },
  });
};

export const postTemplateStoreById = ({
  templateName,
  templateId,
  organizationId,
  organizationGroupId,
}: PostTemplateStoreByIdRequest) => {
  const params: {
    organizationUuid: string;
    organizationGroupUuid?: string;
  } = {
    organizationUuid: organizationId,
  };

  if (organizationGroupId) {
    params.organizationGroupUuid = organizationGroupId;
  }

  return getAPIResponseData({
    url: `/template-stores/${templateId}`,
    method: "POST",
    params,
    data: {
      templateName,
    },
  });
};
