import styled from "styled-components";

export const RootLayout = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 144rem;
  min-height: calc(100vh - var(--header-height));
  background-color: var(--grey-200);
`;
