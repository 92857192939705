import { Board } from "components/Common/Board/Board";
import { FaqSection } from "pages/setting/faq";
import React from "react";
import styled from "styled-components";
import LandingNavigation from "../LandingNavigation";
import { MainContentLayout } from "layout/DefaultLayout";

const LandingFaq = () => {
  return (
    <MainContentLayout>
      <LandingNavigation />
      <FaqBoard>
        <FaqSection />
      </FaqBoard>
    </MainContentLayout>
  );
};

export default LandingFaq;

const FaqBoard = styled(Board)`
  border-radius: 2rem;
  padding: 8rem;
`;
