import { Body2Medium, Body2Regular } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterContainer>
      <Body2Medium className="termsText">
        <a
          href="https://softsquared.notion.site/426ec1343ec34be786b1212aa80592a3?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          서비스약관
        </a>{" "}
        |{" "}
        <a
          href="https://softsquared.notion.site/b145b6a389704010a4a8c7c59f625d86?pvs=4"
          target="_blank"
          rel="noreferrer"
        >
          개인정보처리방침
        </a>{" "}
        | <Link to="/setting/support">고객지원</Link>
      </Body2Medium>
      <Body2Regular>
        (주) 이폼 | 대표자명: 채성현 | 사업자등록번호: 445-87-03050 |
        통신판매업신고번호: 2024-서울구로-1380호
        <br />
        대표번호: 1577-0821 | 주소: 서울특별시 구로구 디지털로34길 55,
        비201-161-11호
      </Body2Regular>
      <Body2Regular>copyright(c) 2024 이폼 All right reserved.</Body2Regular>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  width: 100%;
  padding: 3rem 4rem;
  background-color: var(--bg-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-left: 1px solid var(--grey-300);
  color: var(--grey-600);

  .termsText {
    color: var(--text-default);
  }
`;
