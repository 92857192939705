import axios from "axios";
import {
  GetSignDetailsResponse,
  GetSignPhoneVerifyValidRequest,
  GetSignPhoneVerifyValidResponse,
  PostSignPasswordVerifyRequest,
  PostSignPasswordVerifyResponse,
  PostSignRequest,
  PostSignVerifyRequest,
  PostSignVerifyResponse,
} from "models/Sign";
import getAPIResponseData from "utils/getAPIResponse";

export const postSignVerify = (data: PostSignVerifyRequest) => {
  return getAPIResponseData<PostSignVerifyResponse, PostSignVerifyRequest>({
    url: `/sign/verify`,
    method: "POST",
    data,
  });
};

export const postSignPasswordVerify = ({
  contractReceiverUuid,
  data,
}: {
  contractReceiverUuid: string;
  data: PostSignPasswordVerifyRequest;
}) => {
  return getAPIResponseData<
    PostSignPasswordVerifyResponse,
    PostSignPasswordVerifyRequest
  >({
    url: `/sign/${contractReceiverUuid}/password-verify`,
    method: "POST",
    data,
  });
};

export const getSignDetails = () => {
  return getAPIResponseData<GetSignDetailsResponse>({
    url: `/sign/details`,
    method: "GET",
  });
};

export const postSignDeny = (data: { reason: string }) => {
  return getAPIResponseData({
    url: `/sign/deny`,
    method: "POST",
    data,
  });
};

export const getSignAssetsPresignedUrl = async ({
  file,
  fileName,
}: {
  file: File;
  fileName: string;
}) => {
  const url = await getAPIResponseData<string>({
    url: `/sign/assets/presigned-url?fileName=${fileName}`,
    method: "GET",
  });

  try {
    await axios({
      url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  } finally {
    return url;
  }
};

export const postSign = (data: PostSignRequest) => {
  return getAPIResponseData({
    url: `/sign`,
    method: "POST",
    data,
  });
};

export const getSignPhoneVerifyValid = ({
  verifyType,
  requestId,
  entityId,
}: GetSignPhoneVerifyValidRequest) => {
  return getAPIResponseData<GetSignPhoneVerifyValidResponse>({
    url: `/sign/phone-verify/valid?verifyType=${verifyType}&requestId=${requestId}&entityId=${entityId}`,
    method: "GET",
  });
};
