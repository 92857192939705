import {
  mergeQueryKeys,
  inferQueryKeyStore,
} from "@lukemorales/query-key-factory";

import { organizationQueryKey } from "./organization";
import { organizationGroupQueryKey } from "./organizationGroup";
import { userQueryKey } from "./user";
import { contractQueryKey } from "./contract";
import { quickActionQueryKey } from "./quickAction";
import { signatureQueryKey } from "./signatures";
import { noticeQueryKey } from "./notice";
import { templateQueryKey } from "./template";
import { templateStoreQueryKey } from "./templateStore";
import { directURLQueryKey } from "./directURL";
import { notificationQueryKey } from "./notification";
import { contactQueryKey } from "./contact";
import { dropdownListQueryKey } from "./dropdownList";
import { mailTemplateQueryKey } from "./mailTemplate";
import { paymentQueryKey } from "./payment";
import { signQueryKey } from "./sign";

export const queries = mergeQueryKeys(
  organizationQueryKey,
  organizationGroupQueryKey,
  userQueryKey,
  contractQueryKey,
  quickActionQueryKey,
  signatureQueryKey,
  noticeQueryKey,
  templateQueryKey,
  templateStoreQueryKey,
  directURLQueryKey,
  notificationQueryKey,
  contactQueryKey,
  dropdownListQueryKey,
  mailTemplateQueryKey,
  paymentQueryKey,
  signQueryKey
);

export type QueryKeys = inferQueryKeyStore<typeof queries>;
