import { Board } from "pages/dashboard/style";
import styled from "styled-components";

export const ConfigNavigation = styled.nav`
  background-color: var(--bg-white);
  width: 100%;

  > div {
    display: flex;
    padding: 1.5rem 4rem;
    justify-content: space-between;
    button {
      padding: 0.8rem 2.4rem;
    }
  }

  box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.05);
`;

export const ConfigContent = styled(Board)`
  margin: auto;
  padding: 8rem;

  input {
    margin-top: 1.2rem;
  }
  .config-subtitle {
    margin-top: 2rem;
  }
  .config-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25rem;
  }
`;

export const ContentLayout = styled.main`
  width: 100%;
  max-width: 101.25rem;
  min-width: 71.25rem;
  min-height: 100%;
  margin: 3rem auto 3rem auto;
`;
