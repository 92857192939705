import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postSignaturesByIdFavorites } from "apis/signature";
import { queries } from "config/queryKey";

const useSignaturesFavoritesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postSignaturesByIdFavorites,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.signature.list.queryKey,
      });
    },
  });
};

export default useSignaturesFavoritesMutation;
