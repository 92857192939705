import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getUserDetails, getUserExtraDetails } from "apis/user";

export const userQueryKey = createQueryKeys("user", {
  detail: {
    queryKey: null,
    queryFn: getUserDetails,
  },
  extraDetail: {
    queryKey: null,
    queryFn: getUserExtraDetails,
  },
});
