import React, { forwardRef } from "react";
import { ToggleContainer } from "./ToggleStyledComponents";

interface ToggleProps {
  isSelected: boolean;
  handleToggle?: () => void;
  isDisabled?: boolean;
}

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  ({ isSelected, handleToggle, isDisabled = false }, ref) => {
    return (
      <ToggleContainer
        onClick={handleToggle}
        $isSelected={isSelected}
        disabled={isDisabled}
        ref={ref}
        type="button"
      >
        <div className="toggleCircle" />
      </ToggleContainer>
    );
  }
);

Toggle.displayName = "Toggle";

export default Toggle;
