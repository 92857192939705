import React from "react";
import { ReactComponent as InformationIcon } from "assets/common/20px/icon_information_small.svg";
import { Body3Regular, Body3SemiBold } from "components/TextStyle";
import styled from "styled-components";

interface PlanBottomProps {
  item: {
    text?: string;
    gray?: string;
    bold?: string;
    icon?: boolean;
  };
}

const PlanBottom = ({ item }: PlanBottomProps) => {
  return (
    <PlanBottomItemContainer className="itemContainer">
      <Body3Regular className="itemText" as={"li"}>
        {item?.text} <span className="caption">{item?.gray}</span>
        <Body3SemiBold as={"span"}>{item?.bold}</Body3SemiBold>
      </Body3Regular>

      {item?.icon && (
        <InformationIcon width={20} height={20} stroke="#3A4552" />
      )}
    </PlanBottomItemContainer>
  );
};

export default PlanBottom;

const PlanBottomItemContainer = styled.div`
  display: flex;
  align-items: center;

  .itemText {
    list-style: disc;
    color: var(--text-default);
    text-indent: -2rem;
    margin-left: 1.4rem;
    word-break: keep-all;

    ::marker {
      color: var(--text-default);
    }

    .caption {
      color: #666f7b;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.6px;
    }
  }
`;
