import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getOrganizationsList,
  getOrganizationsMember,
  getOrganizationsMembersInvitationValidation,
} from "apis/organization";
import { Permission, SearchField } from "models/Organization";

export const organizationQueryKey = createQueryKeys("organization", {
  list: {
    queryKey: null,
    queryFn: getOrganizationsList,
  },
  members: ({
    organization,
    managementMenu,
    filterAuthority,
    filterState,
    searchValue,
  }) => ({
    queryKey: [
      organization,
      managementMenu,
      filterAuthority,
      filterState,
      searchValue,
    ],
    queryFn: () =>
      getOrganizationsMember({
        organizationUuid: organization,
        memberStatusType: managementMenu,
        permissionToSearch:
          filterAuthority === "all" ? null : (filterAuthority as Permission),
        searchField:
          filterState === "all" ? null : (filterState as SearchField),
        searchValue: searchValue === "" ? null : searchValue,
      }),
  }),
  invitation: (organizationMemberUuid?: string) => ({
    queryKey: [organizationMemberUuid],
    queryFn: () =>
      getOrganizationsMembersInvitationValidation(organizationMemberUuid),
  }),
});
