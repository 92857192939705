import React, { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "assets/common/24px/icon_left_default.svg";
import { useNavigate } from "react-router-dom";
import {
  Body1SemiBold,
  Body3Regular,
  Body3SemiBold,
  CaptionRegular,
  Header1,
} from "components/TextStyle";
import { Board } from "components/Common/Board/Board";
import useNotificationList from "hooks/queries/notification/useNotificationList";
import { useInView } from "react-intersection-observer";
import { format, isToday, isYesterday, subDays } from "date-fns";
import { NotificationList } from "models/Notification";
import { Link } from "react-router-dom";
import { NOTIFICATION_TYPE_MAP } from "config/notification";

interface NotificationGroups {
  오늘: NotificationList["contents"];
  어제: NotificationList["contents"];
  "2일 전": NotificationList["contents"];
}

const AlertDashboardPage = () => {
  const navigate = useNavigate();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useNotificationList();
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const groupedNotifications = useMemo(() => {
    if (!data) return;

    const allNotifications = data.pages.flatMap(
      (page) => page.notificationList.contents
    );

    const grouped = allNotifications.reduce<NotificationGroups>(
      (acc, notification) => {
        const date = new Date(notification.createdAt);

        if (isToday(date)) {
          acc["오늘"].push(notification);
        } else if (isYesterday(date)) {
          acc["어제"].push(notification);
        } else if (isToday(subDays(date, 2))) {
          acc["2일 전"].push(notification);
        }

        return acc;
      },
      {
        오늘: [],
        어제: [],
        "2일 전": [],
      }
    );

    return grouped;
  }, [data]);

  if (!groupedNotifications || !data) return null;

  return (
    <>
      <AlertDashboardNavigation>
        <LeftArrowIcon onClick={() => navigate("/dashboard")} />
        <Header1>알림</Header1>
      </AlertDashboardNavigation>
      <AlertDashboardLayout>
        <Body1SemiBold>
          모든 알림 ({data.pages[0].notificationList.totalElements})
        </Body1SemiBold>

        {(
          Object.entries(groupedNotifications) as [
            keyof NotificationGroups,
            NotificationList["contents"]
          ][]
        ).map(
          ([dateGroup, notifications]) =>
            notifications.length > 0 && (
              <div key={dateGroup} className="alert-group">
                <Body3SemiBold>{dateGroup}</Body3SemiBold>
                <AlertList>
                  {notifications.map((notification, index) => (
                    <Link
                      to={
                        NOTIFICATION_TYPE_MAP[notification.notificationType]
                          ?.link
                      }
                      key={notification.notificationUuid}
                    >
                      <AlertListItem
                        title={
                          NOTIFICATION_TYPE_MAP[notification.notificationType]
                            ?.text
                        }
                        content={notification.content}
                        date={format(notification.createdAt, "yyyy.MM.dd")}
                      />
                      {index < notifications.length - 1 && <hr />}
                    </Link>
                  ))}
                </AlertList>
              </div>
            )
        )}

        {hasNextPage && (
          <LoadingIndicator ref={ref}>
            {isFetchingNextPage ? "로딩 중..." : "더 보기"}
          </LoadingIndicator>
        )}
      </AlertDashboardLayout>
    </>
  );
};

export default AlertDashboardPage;

const LoadingIndicator = styled.div`
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: var(--text-secondary);
`;

const AlertDashboardNavigation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.8rem;
  margin-bottom: 4.4rem;

  > svg {
    cursor: pointer;
  }
`;

const AlertList = styled.ul`
  padding: 2.4rem;
  background-color: var(--bg-dark);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  border-radius: 0.5rem;

  > hr:last-child {
    display: none;
  }
`;

const AlertDashboardLayout = styled(Board)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 8rem;

  .alert-group {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
`;

interface AlertListItemProps {
  title: string;
  content: string;
  date: string;
}

const AlertListItem = ({ title, content, date }: AlertListItemProps) => {
  return (
    <AlertListItemLayout>
      <Body3SemiBold>{title}</Body3SemiBold>
      <Body3Regular>{content}</Body3Regular>
      <CaptionRegular>{date}</CaptionRegular>
    </AlertListItemLayout>
  );
};

const AlertListItemLayout = styled.li`
  > :first-child {
    color: var(--text-middle-emphasis);
    margin-bottom: 0.6rem;
  }

  > :last-child {
    color: var(--text-secondary);
    margin-top: 1.2rem;
  }
`;
