import Button from "components/Common/Button/Button";
import ContractConfigForm, {
  ContractConfigFormData,
} from "components/template/Config/ContractConfigForm";
import {
  ConfigContent,
  ConfigNavigation,
  ContentLayout,
} from "components/template/Config/Layout";
import MailConfigForm, {
  MailConfigFormData,
} from "components/template/Config/MailConfigForm";
import MultipleReceiverConfigForm from "components/template/Config/MultipleReceiverConfigForm";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

interface FormData extends MailConfigFormData, ContractConfigFormData {}

const TemplateSendConfigMultiplePage = () => {
  const methods = useForm<FormData>({
    defaultValues: {
      mail: "mailTemplate",
    },
  });

  return (
    <>
      <ConfigNavigation>
        <div>
          <Button colorType="ghost" size="medium">
            이전
          </Button>
          <Button colorType="primary" size="medium">
            다음
          </Button>
        </div>
      </ConfigNavigation>
      <ContentLayout>
        <ConfigContent>
          <FormProvider {...methods}>
            <ContractConfigForm />
            <MultipleReceiverConfigForm />
            <MailConfigForm />
          </FormProvider>
        </ConfigContent>
      </ContentLayout>
    </>
  );
};

export default TemplateSendConfigMultiplePage;
