import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOrganizationByIdWithdraw } from "apis/organization";
import { queries } from "config/queryKey";

const useOrganizationsDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteOrganizationByIdWithdraw,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.organization.list.queryKey,
      });
    },
  });
};

export default useOrganizationsDeleteMutation;
