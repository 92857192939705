import React from "react";

const Divider = () => {
  return (
    <hr
      style={{
        width: "100%",
        border: "none",
        height: "1px",
        backgroundColor: "var(--stroke-light)",
      }}
    />
  );
};

export default Divider;
