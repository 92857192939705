import Button from "components/Common/Button/Button";
import DatePicker from "components/Common/DatePicker/DatePicker";
import Input from "components/Common/Input/Input";
import ActionModal from "components/Common/Modal/ActionModal";
import Toggle from "components/Common/Toggle/Toggle";
import { Body1Regular, Body2Medium } from "components/TextStyle";
import { REGEX } from "config/regex";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";

const DESCRIPTION = [
  <>
    직접 만나서 작성하거나 사이트 / 카페 등에 작성 URL을 노출 하여 불특정 다수가
    쉽게 작성하도록 활용할 수 있습니다.
  </>,
  <>
    URL 생성시 설정한 이름, 건수, 유효기간은 [Direct URL 수정]에서 수정
    가능합니다.
  </>,
];

interface TemplateDirectUrlModalProps {
  closeModal: () => void;
}

interface FormData {
  urlName: string;
  urlCount: string;
  urlExpiration: Date;
  urlPassword: {
    isUsed: boolean;
    password: string;
  };
  phoneAuth: {
    isUsed: boolean;
  };
  approvalAuthority: {
    isUsed: boolean;
    email: string;
  };
}

const TemplateDirectUrlModal = ({
  closeModal,
}: TemplateDirectUrlModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const { data: creditData } = useContractRemainCreditsQuery();

  const generateURL = (e: FormData) => {
    console.log(e);
  };

  if (!creditData) return null;

  const {
    remainContractCredit: { remainCredits },
  } = creditData;

  return (
    <ActionModal
      isCloseIconButton
      title="Direct URL 생성"
      handleModalClose={closeModal}
      widthFull
    >
      <Layout>
        <ul>
          {DESCRIPTION.map((desc, index) => (
            <li key={index}>
              <Body1Regular>{desc}</Body1Regular>
            </li>
          ))}
        </ul>

        <FormLayout
          onSubmit={handleSubmit(generateURL)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <FormItem
            title="URL이름"
            Input={<Input placeholder="{템플릿 명}" {...register("urlName")} />}
          />
          <FormItem
            title="URL 작성건수"
            Input={
              <Input
                type="number"
                style={{
                  paddingRight: "3rem",
                }}
                {...register("urlCount", {
                  onChange: (e) => {
                    const value = parseInt(e.target.value);
                    if (!isNaN(value) && value > remainCredits) {
                      e.target.value = remainCredits;
                    }
                  },
                })}
              />
            }
            inputAddon="건"
            subDescription={`(잔여 건수: ${remainCredits}건)`}
          />
          <FormItem
            title="URL 유효기간"
            Input={
              <Controller
                control={control}
                name="urlExpiration"
                render={({ field: { value, onChange, ref } }) => (
                  <DatePicker
                    selectedDate={value}
                    onChange={onChange}
                    ref={ref}
                    popperProps={{
                      strategy: "fixed",
                    }}
                  />
                )}
              />
            }
            subDescription="(2024. 06. 06 까지)"
          />
          <FormItem
            title={
              <Controller
                control={control}
                name="urlPassword.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    URL 비밀번호
                  </>
                )}
              />
            }
            Input={
              <Input
                placeholder="4자~12자"
                {...register("urlPassword.password", {
                  required:
                    getValues("urlPassword.isUsed") &&
                    "4자~12자로 입력해주세요.",
                  pattern: {
                    value: REGEX.contract.password,
                    message: "4자~12자로 입력해주세요.",
                  },
                })}
                isError={!!errors.urlPassword?.password}
                errorMessage={errors.urlPassword?.password?.message}
                isDisabled={!watch("urlPassword.isUsed")}
              />
            }
          />

          <FormItem
            title={
              <Controller
                control={control}
                name="phoneAuth.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    휴대폰 본인 인증
                  </>
                )}
              />
            }
          />

          <FormItem
            title={
              <Controller
                control={control}
                name="approvalAuthority.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    승인 권한
                  </>
                )}
              />
            }
            Input={
              <Input
                placeholder="승인 이메일"
                {...register("approvalAuthority.email", {
                  required:
                    getValues("approvalAuthority.isUsed") &&
                    "유효한 이메일 주소를 입력해 주세요",
                  pattern: {
                    value: REGEX.email,
                    message: "유효한 이메일 주소를 입력해 주세요",
                  },
                })}
                isError={!!errors.approvalAuthority?.email}
                errorMessage={errors.approvalAuthority?.email?.message}
                isDisabled={!watch("approvalAuthority.isUsed")}
              />
            }
          />
          <GenerateURLLayout>
            <Input />
            <Button colorType="dark" size="medium" type="submit">
              URL 생성
            </Button>
          </GenerateURLLayout>
        </FormLayout>
      </Layout>
    </ActionModal>
  );
};

export default TemplateDirectUrlModal;

const Layout = styled.div`
  li {
    margin-left: 1.2rem;
    margin-bottom: 1.2rem;
    list-style: decimal;

    ::marker {
      color: var(--text-default);
    }
  }
`;

const FormLayout = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  max-height: 60vh;
  height: 100%;
  overflow-y: scroll;
`;

const GenerateURLLayout = styled.div`
  display: flex;
  gap: 1.2rem;

  margin-top: 3rem;
  > div:first-child {
    flex: 1;
  }
`;

interface FormItemProps {
  title: React.ReactNode;
  Input?: React.ReactNode;
  inputAddon?: string;
  subDescription?: React.ReactNode;
}

const FormItem = ({
  title,
  Input,
  inputAddon,
  subDescription,
}: FormItemProps) => (
  <FormItemWrapper>
    <Body2Medium className="title-wrapper">{title}</Body2Medium>
    <InputContainer>
      <div className="input-wrapper">
        {Input}
        {inputAddon && <InputAddon>{inputAddon}</InputAddon>}
      </div>
      {subDescription && <SubDescription>{subDescription}</SubDescription>}
    </InputContainer>
  </FormItemWrapper>
);

const FormItemWrapper = styled.div`
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 1.2rem;
  }
  .input-wrapper {
    position: relative;
    flex: 1;
    min-width: 16rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  flex: 1;
`;

const InputAddon = styled(Body1Regular)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

const SubDescription = styled(Body2Medium)`
  color: var(--text-emphasis);
  flex: 1;
`;
