import axios, {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { JWT_KEY, JWT_REFRESH_KEY } from "../../config/constant";

export type BaseResponse<T = any> = {
  isSuccess: boolean;
  message: string;
  code: number;
  result: T;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<BaseResponse>>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T>(config: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const request: CustomInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 20000,
  headers: {
    accept: "application/json",
  },
});

request.interceptors.request.use(
  (config) => {
    const jwt = window.localStorage.getItem(JWT_KEY);

    if (config.url?.split("/")[1] === "sign") {
      config.baseURL = process.env.REACT_APP_SIGN_API;

      const accessJwt = sessionStorage.getItem("accessJwt");

      if (accessJwt) config.headers.Authorization = `Bearer ${accessJwt}`;
      return config;
    }

    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const contentType = response.headers["content-type"];
    if (contentType !== "application/json") {
      return response;
    }
    console.log("network log", response);
    if (response.data.isSuccess) {
      return response.data.result;
    } else {
      return Promise.reject(response.data.message);
    }
  },
  async (error: AxiosError<BaseResponse>) => {
    if (error.response?.data.code === 401) {
      if (!error.config) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      const refreshToken = window.localStorage.getItem(JWT_REFRESH_KEY);

      console.log(refreshToken);
      if (refreshToken) {
        try {
          const {
            userInfo: { accessJwt },
          } = await request.post<{
            userInfo: {
              uuid: string;
              accessJwt: string;
              refreshJwt: string;
            };
          }>(`/auths/access-tokens`, {
            refreshJwt: refreshToken,
          });

          console.log(accessJwt);
          window.localStorage.setItem(JWT_KEY, accessJwt); // 새 액세스 토큰 저장
          originalRequest.headers["Authorization"] = `Bearer ${accessJwt}`;
          return request(originalRequest); // 원 요청 재시도
        } catch (refreshError) {
          console.log("Failed to refresh token:", refreshError);
          window.localStorage.removeItem(JWT_KEY);
          window.localStorage.removeItem(JWT_REFRESH_KEY);
          // 로그인 페이지로 리다이렉트하거나 로그인 요청을 유도할 수 있습니다.
        }
      }
    }
    return Promise.reject(
      error.code === "ERR_NETWORK"
        ? "허용되지 않은 네트워크 접근입니다."
        : error
    );
  }
);

export default request.request;
