import Input from "components/Common/Input/Input";
import {
  Body1Regular,
  Body1SemiBold,
  Body3Regular,
} from "components/TextStyle";
import React from "react";
import ToggleController from "./ToggleController";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { REGEX } from "config/regex";
export interface ContractConfigFormData {
  contractName: string;
  password: {
    password: string;
    isUsed: boolean;
  };
  isPhoneNumberAuth: {
    isUsed: boolean;
  };
  expirationDate: {
    date: string;
    isUsed: boolean;
  };
}

const ContractConfigForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ContractConfigFormData>();

  console.log(errors);
  return (
    <>
      <ContractConfigLayout>
        <Body1SemiBold>계약명</Body1SemiBold>
        <Input
          {...register("contractName")}
          placeholder="계약명을 입력하세요"
        />
      </ContractConfigLayout>
      <ContractConfigLayout>
        <div>
          <Body1SemiBold>인증방식</Body1SemiBold>
          <ConfigContentDescription>
            전체 수신자에게 적용되는 인증 방식을 설정합니다.
          </ConfigContentDescription>
        </div>
        <div>
          <Body1Regular className="config-subtitle config-controller">
            암호 사용
            <ToggleController control={control} name="password.isUsed" />
          </Body1Regular>
          <Input
            {...register("password.password", {
              pattern: {
                value: REGEX.contract.password,
                message:
                  "암호는 영문, 숫자, 특수문자를 포함한 4~15자여야 합니다.",
              },
            })}
            isError={!!errors.password?.password}
            errorMessage={errors.password?.password?.message}
            placeholder="암호 입력(4~15자)"
          />
        </div>
        <div>
          <Body1Regular className="config-subtitle config-controller">
            휴대폰 본인 인증
            <ToggleController
              control={control}
              name="isPhoneNumberAuth.isUsed"
            />
          </Body1Regular>
          <ConfigContentDescription>
            휴대폰 본인인증은 휴대전화번호가 입력된 수신자에게만 적용됩니다.
          </ConfigContentDescription>
        </div>
      </ContractConfigLayout>
      <ContractConfigLayout>
        <Body1SemiBold className="config-controller">
          만료기한
          <ToggleController control={control} name="expirationDate.isUsed" />
        </Body1SemiBold>
        <ConfigContentDescription>
          전체 수신자에게 적용되는 인증 방식을 설정합니다.
        </ConfigContentDescription>
        <Input {...register("expirationDate.date")} />
        {/* DatePicker로 전환 필요 */}
      </ContractConfigLayout>
    </>
  );
};

export default ContractConfigForm;

const ContractConfigLayout = styled.div`
  width: 53rem;
  margin-bottom: 4rem;
`;

const ConfigContentDescription = styled(Body3Regular)`
  margin-top: 0.8rem;
  color: var(--text-secondary);
`;
