import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getSignDetails, getSignPhoneVerifyValid } from "apis/sign";

export const signQueryKey = createQueryKeys("sign", {
  details: {
    queryKey: null,
    queryFn: getSignDetails,
  },
  phoneVerifyValid: ({ verifyType, requestId, entityId }) => ({
    queryKey: [verifyType, requestId, entityId],
    queryFn: () => getSignPhoneVerifyValid({ requestId, verifyType, entityId }),
  }),
});
