const splitTextChunk = (text: string, step = 2) => {
  const chunks = Array.from({ length: Math.ceil(text.length / step) }, (_, i) =>
    text.substring(i * step, i * step + step)
  );

  if (text.length % step !== 0) {
    chunks[chunks.length - 1] += "인";
  }

  return chunks;
};

export default splitTextChunk;
