import { Board } from "components/Common/Board/Board";
import { Header1 } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import OrganizationItem from "./OrganizationItem";
import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";

const ProfileInvitedOrganizationPage = () => {
  const { data } = useOrganizationListQuery();
  return (
    <>
      <Header1>가입된 조직</Header1>
      <InvitedOrganizationLayout>
        <ul>
          {data?.organizationList.map((organization, index) => (
            <div key={organization.organizationUuid}>
              <OrganizationItem
                key={organization.organizationUuid}
                index={index + 1}
                id={organization.organizationUuid}
                title={organization.companyName}
              />
              <hr />
            </div>
          ))}
        </ul>
      </InvitedOrganizationLayout>
    </>
  );
};

export default ProfileInvitedOrganizationPage;

const InvitedOrganizationLayout = styled(Board)`
  margin-top: 2rem;

  hr {
    width: 100%;
    margin: 0;
    height: 0.1rem;
    border: none;
    background-color: var(--stroke-light);
  }

  > div {
    hr:last-child {
      display: none;
    }
  }
`;
