import {
  DeleteOrganizationByIdWithdrawRequest,
  DeleteOrganizationsMembersWithdraw,
  GetOrganizationMemberResponse,
  GetOrganizationsListResponse,
  GetOrganizationsMemberRequest,
  PatchOrganizationsMembersPermissionsRequest,
  PostOrganizationsMembersInviteRequest,
} from "models/Organization";
import getAPIResponseData from "utils/getAPIResponse";

export const getOrganizationsList = () => {
  return getAPIResponseData<GetOrganizationsListResponse>({
    url: "/organizations/list",
    method: "GET",
  });
};

export const deleteOrganizationByIdWithdraw = ({
  id,
  withdrawReason,
}: {
  id: string;
} & DeleteOrganizationByIdWithdrawRequest) => {
  return getAPIResponseData({
    url: `/organizations/${id}/withdraw`,
    method: "DELETE",
    data: { withdrawReason },
  });
};

export const getOrganizationsMember = ({
  organizationUuid,
  memberStatusType,
  permissionToSearch,
  searchField,
  searchValue,
}: GetOrganizationsMemberRequest) => {
  return getAPIResponseData<GetOrganizationMemberResponse[]>({
    url: `/organizations/members/${organizationUuid}`,
    method: "GET",
    params: {
      memberStatusType,
      permissionToSearch,
      searchField,
      searchValue,
    },
  });
};

export const postOrganizationsMembersInvite = (
  data: PostOrganizationsMembersInviteRequest
) => {
  return getAPIResponseData({
    url: `/organizations/members/invite`,
    method: "POST",
    data: data,
  });
};

export const patchOrganizationsMembersPermissions = (
  data: PatchOrganizationsMembersPermissionsRequest
) => {
  return getAPIResponseData({
    url: "/organizations/members/permissions",
    method: "PATCH",
    data: data,
  });
};

export const deleteOrganizationsMembersWithdraw = (
  data: DeleteOrganizationsMembersWithdraw
) => {
  return getAPIResponseData({
    url: "/organizations/members/withdraw",
    method: "DELETE",
    data: data,
  });
};

export const deleteOrganizationsMembersInvitationCancel = (
  organzationMemberUuid: string
) => {
  return getAPIResponseData({
    url: `/organizations/members/invitation-cancel/${organzationMemberUuid}`,
    method: "DELETE",
  });
};

export const getOrganizationsMembersInvitationValidation = (
  organzationMemberUuid?: string
) => {
  return getAPIResponseData<{ valid: boolean }>({
    url: `/organizations/members/invitation/validation?organizationMemberUuid=${organzationMemberUuid}`,
    method: "GET",
  });
};
