import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getOrganizationsGroupByIdGroupList,
  getOrganizationsGroupMembers,
  getOrganizationsGroups,
} from "apis/organizationGroup";
import { Organization } from "models/Organization";

export const organizationGroupQueryKey = createQueryKeys("organizationGroup", {
  detail: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    contextQueries: {
      groupList: {
        queryKey: [id],
        queryFn: () => {
          if (!id) return;
          return getOrganizationsGroupByIdGroupList(id);
        },
      },
    },
  }),
  members: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    queryFn: () => {
      if (!id) return;
      return getOrganizationsGroupMembers(id);
    },
  }),
  list: ({ groupId, memberValue }) => ({
    queryKey: [groupId, memberValue],
    queryFn: () =>
      getOrganizationsGroups({
        organizationGroupUuid: groupId,
        searchKeyword: memberValue,
      }),
  }),
});
