import Button from "components/Common/Button/Button";
import TextArea from "components/Common/Input/TextArea";
import ActionModal from "components/Common/Modal/ActionModal";
import { Body2Regular } from "components/TextStyle";
import useOrganizationsDeleteMutation from "hooks/mutations/organization/useOrganizationsDeleteMutation";
import useModal from "hooks/useModal";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface FormData {
  reason: string;
}

interface OrganizationItemProps {
  index: number;
  title: string;
  id: string;
}

const OrganizationItem = ({ index, title, id }: OrganizationItemProps) => {
  const { openModal, closeModal, isOpen } = useModal();
  const {
    register,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const { mutate } = useOrganizationsDeleteMutation();

  return (
    <>
      {isOpen && (
        <ActionModal
          title="가입된 조직 탈퇴"
          handleModalClose={closeModal}
          handleCancelButton={closeModal}
          handleConfirmButton={() => {
            mutate(
              {
                id: id,
                withdrawReason: getValues("reason"),
              },
              {
                onSuccess: () => {
                  closeModal();
                },
              }
            );
          }}
        >
          <TextArea
            placeholder="탈퇴 사유를 입력해주세요"
            maxLength={100}
            {...register("reason", {
              required: "탈퇴 사유를 입력해주세요",
            })}
            isError={errors.reason?.message ? true : false}
            rows={5}
            _style={{ resize: "none" }}
          />
        </ActionModal>
      )}
      <OrganizationItemLayout>
        <div>
          <Body2Regular className="organization-item-index">
            {index}
          </Body2Regular>
          <Body2Regular className="organization-item-title">
            {title}
          </Body2Regular>
        </div>
        <Button colorType="tertiary" size="small" onClick={openModal}>
          탈퇴하기
        </Button>
      </OrganizationItemLayout>
    </>
  );
};

const OrganizationItemLayout = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  > div {
    display: flex;
  }

  > button {
    margin-right: 4rem;
  }

  .organization-item-index {
    color: var(--primary-600);
    padding: 1.6rem 2.4rem;
  }

  .organization-item-title {
    padding: 1.6rem 2.4rem;
  }
`;

export default OrganizationItem;
