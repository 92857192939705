import { JWT_KEY, JWT_REFRESH_KEY } from "config/constant";
import useUserState from "./useUserState";

const useResetUser = () => {
  const [_, setUser] = useUserState();

  return () => {
    window.localStorage.removeItem(JWT_REFRESH_KEY);
    window.localStorage.removeItem(JWT_KEY);
    setUser({
      uuid: undefined,
      organization: undefined,
      organizationGroup: undefined,
    });
  };
};

export default useResetUser;
