import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import { Body1Medium, Body1SemiBold, Body3Regular } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/common/20px/icon_plus.svg";
import { useFieldArray, useFormContext } from "react-hook-form";

export interface SingleReceiverConfigFormData {
  receiver: {
    name: string;
    email: string;
    phoneNumber: string;
  }[];
}

const SingleReceiverConfigForm = () => {
  const { control, register } = useFormContext<SingleReceiverConfigFormData>();
  const { fields, append } = useFieldArray({
    control,
    name: "receiver",
  });

  const handleAddReceiverClick = () => {
    append({
      name: "",
      email: "",
      phoneNumber: "",
    });
  };
  return (
    <>
      <SingleReceiverLayout>
        <div className="single-receiver-form-title">
          <Body1SemiBold>수신자</Body1SemiBold>
          <LeftSendTitle>잔여건수: 1건</LeftSendTitle>
        </div>
        {fields.map((field, index) => (
          <div key={field.id} className="single-receiver-form-from">
            <Input
              placeholder="이름"
              {...register(`receiver.${index}.name` as const)}
            />
            <Input
              placeholder="이메일"
              {...register(`receiver.${index}.email` as const)}
            />
            <Input
              placeholder="휴대폰 번호"
              {...register(`receiver.${index}.phoneNumber` as const)}
            />
            <div
              style={{
                marginLeft: "2rem",
              }}
            >
              <Body1Medium>개별인증: </Body1Medium>
              <Button colorType="tertiary" size="small">
                설정하기
              </Button>
            </div>
          </div>
        ))}
      </SingleReceiverLayout>
      <AddReceiverButton onClick={handleAddReceiverClick}>
        <Body3Regular
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          수신자 추가하기
          <PlusIcon />
        </Body3Regular>
      </AddReceiverButton>
    </>
  );
};

export default SingleReceiverConfigForm;

const SingleReceiverLayout = styled.div`
  margin-bottom: 3.2rem;
  .single-receiver-form-title {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 2rem;
  }
  .single-receiver-form-from {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 1rem;

    > div {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }
    input {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

const LeftSendTitle = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

const AddReceiverButton = styled.button`
  width: 100%;
  padding: 3rem 0;
  border-radius: 12px;
  border: 1.5px dashed #b7d3fc;
  background: #f1f6ff;
  margin-bottom: 4rem;
  color: var(--text-secondary);
`;
