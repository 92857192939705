import React, { PropsWithChildren } from "react";
import { RootLayout } from "./RootLayout";
import styled from "styled-components";
import {
  Header,
  LabelContainer,
  LogoContainer,
} from "components/AppHeader/styles";
import { Link } from "react-router-dom";
import LogoIcon from "assets/common/icon_logo.svg";
import { Body1Medium } from "components/TextStyle";

export function Receiverlayout({ children }: PropsWithChildren) {
  return (
    <RootLayout>
      <Header>
        <LogoContainer>
          <Link to={"/"}>
            <img src={LogoIcon} alt="logoIcon" />
          </Link>
          <div className="line" />
        </LogoContainer>
        <LabelContainer>
          <Body1Medium>수신자 본인 확인</Body1Medium>
        </LabelContainer>
      </Header>
      <MainContent>{children}</MainContent>
    </RootLayout>
  );
}

const MainContent = styled.main`
  display: flex;
  height: calc(100dvh - 6.8rem);
`;
