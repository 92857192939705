import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { postSignatures, postSignaturesPresignedUrl } from "apis/signature";
import axios from "axios";
import { queries } from "config/queryKey";
import { PostSignaturesRequest } from "models/Signature";
import generateRandomString from "utils/generateRandomString";
import removeQueryParameters from "utils/removeQueryParameters";

const useSignaturesMutation = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutatePresignedURL } = useMutation({
    mutationFn: postSignaturesPresignedUrl,
  });

  const { mutateAsync: mutateSignature } = useMutation({
    mutationFn: postSignatures,
  });

  const mutate = async (
    file: File,
    options?: MutateOptions<unknown, Error, PostSignaturesRequest, unknown>
  ) => {
    const fileName = generateRandomString(15);
    const extension = file.name.split(".")[1];

    const presignedURL = await mutatePresignedURL({
      fileName: fileName + "." + extension,
    });

    const { onSuccess: onPropsSuccess, ...restOptions } = options || {};

    try {
      await axios({
        url: presignedURL,
        method: "PUT",
        data: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      mutateSignature(
        {
          signatureImageUrl: removeQueryParameters(presignedURL),
        },
        {
          onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({
              queryKey: queries.signature.list.queryKey,
            });
            onPropsSuccess && onPropsSuccess(data, variables, context);

            return removeQueryParameters(presignedURL);
          },
          ...restOptions,
        }
      );

      return removeQueryParameters(presignedURL);
    } catch (err) {
      throw err;
    }
  };

  return { mutate };
};

export default useSignaturesMutation;
