import {
  DeleteSignaturesByIdFavoritesRequest,
  DeleteSignaturesByIdRequest,
  GetSignaturesListResponse,
  PostSignaturesByIdFavoritesRequest,
  PostSignaturesPresignedUrlRequest,
  PostSignaturesRequest,
} from "models/Signature";
import getAPIResponseData from "utils/getAPIResponse";

export const deleteSignaturesByIdFavorites = ({
  id,
}: DeleteSignaturesByIdFavoritesRequest) => {
  return getAPIResponseData({
    url: `/signatures/${id}/favorites`,
    method: "DELETE",
  });
};

export const deleteSignaturesById = ({ id }: DeleteSignaturesByIdRequest) => {
  return getAPIResponseData({
    url: `/signatures/${id}`,
    method: "DELETE",
  });
};

export const getSignaturesList = () => {
  return getAPIResponseData<GetSignaturesListResponse>({
    url: `/signatures/list`,
    method: "GET",
  });
};

export const postSignatures = ({
  signatureImageUrl,
}: PostSignaturesRequest) => {
  return getAPIResponseData({
    url: `/signatures`,
    method: "POST",
    data: { signatureImageUrl },
  });
};

export const postSignaturesByIdFavorites = ({
  id,
}: PostSignaturesByIdFavoritesRequest) => {
  return getAPIResponseData({
    url: `/signatures/${id}/favorites`,
    method: "POST",
  });
};

export const postSignaturesPresignedUrl = ({
  fileName,
}: PostSignaturesPresignedUrlRequest) => {
  return getAPIResponseData<string, PostSignaturesPresignedUrlRequest>({
    url: `/signatures/presigned-url`,
    method: "POST",
    data: { fileName },
  });
};
