import { Field } from "pages/contract/send";
import {
  ALLOWED_FILE_TYPES,
  ATTACHMENTS,
  IMAGE_TYPES,
  JWT_KEY,
  MAX_FILE_SIZE,
} from "../config/constant";
import { differenceInCalendarDays } from "date-fns";

/**
 * @param {string} email 이메일 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(email);
};

export const isValidPassword = (password: string) => {
  return password.match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/);
};

/**
 * @param {string} phoneNumber 전화번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (input?: string) => {
  if (!input) return;

  const str = input?.replace(/[^0-9]/g, "");

  if (str.length === 9)
    return str
      .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  if (str.length === 10)
    return str
      .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  if (str.length === 11)
    return str
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");

  return str;
};

export const getJwt = () => window.localStorage.getItem(JWT_KEY) ?? "";

export const saveJwt = (jwt: string) => {
  window.localStorage.setItem(JWT_KEY, jwt);
};

export const clearJwt = () => {
  window.localStorage.removeItem(JWT_KEY ?? "");
};

/*export const saveUser = (user: GetUsersUserIdResponse) => {
  window.localStorage.setItem(
    process.env.REACT_APP_LOGIN_INFO ?? "",
    JSON.stringify(user) ?? ""
  );
};*/

export const clearUser = () => {
  window.localStorage.removeItem(process.env.REACT_APP_LOGIN_INFO ?? "");
};

export const getUserEmail = () => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.email ?? "";
  }
};

export const getUserName = (): string => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.name ?? "";
  }
};

export const isValidFileType = (type: string) => {
  const FILETYPE = type.split("/")[1];
  if (!ALLOWED_FILE_TYPES.includes(FILETYPE.toLowerCase())) return false;
  else return true;
};

export const isValidFileSize = (size: number) => {
  if (size > MAX_FILE_SIZE) return false;
  else return true;
};

export const isValidImage = (type: string) => {
  if (!IMAGE_TYPES.includes(type)) return false;
  return true;
};

export const isValidAttachment = (type: string) => {
  if (!ATTACHMENTS.includes(type)) return false;
  return true;
};

export const getDifferencesInDates = (dateTime: Date) => {
  return differenceInCalendarDays(dateTime, new Date()).toLocaleString("ko-kr");
};

export const getDefaultSizeOfFields = (fieldType: Field) => {
  switch (fieldType) {
    case "SIGN":
      return [160, 80];
    case "IMAGE":
      return [240, 140];
    case "CHECKBOX":
      return [44, 44];
    default:
      return [160, 44];
  }
};
