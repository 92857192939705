import DefaultLayout from "layout/DefaultLayout";
import ProfileSignPage from "pages/profile/sign";
import ProfileInvitedOrganizationPage from "pages/profile/invited-organization";
import React, { Suspense } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import TemplateDashboardPage from "pages/template/dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthSignUpFormLoader from "pages/auth/sign-up/form/loader";
import AuthChangePasswordLoader from "pages/auth/change-password/loader";
import TemplateSendLayout from "layout/TemplateSendLayout";
import TemplateSendConfigSinglePage from "./pages/template/send/detail/config/single";
import TemplateSendConfigMultiplePage from "pages/template/send/detail/config/mutiple";
import NoticeDashboardPage from "pages/notice/dashboard";
import NoticeGuidePage from "pages/notice/guide";
import NoticeDashboardDetailPage from "pages/notice/dashboard/detail";
import AlertDashboardPage from "pages/alert/dashboard";
import SignInLoader from "pages/loader";
import LandingLayout from "layout/LandingLayout";
import { RootLayout } from "layout/RootLayout";
import PlanPage from "pages/(landing)/plan";
import LandingFaq from "pages/(landing)/faq";
import LandingSupportPage from "pages/(landing)/support";
import { Receiverlayout } from "layout/ReceiverLayout";
import TemplateDetailPage from "pages/template/detail";

const RootLandingPage = React.lazy(() => import("./pages"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const DashBoardPage = React.lazy(() => import("./pages/dashboard"));
const APIDashboardPage = React.lazy(() => import("./pages/api/dashboard"));
const APIGettingStartedPage = React.lazy(
  () => import("./pages/api/getting-started")
);
const AuthFindPasswordPage = React.lazy(
  () => import("./pages/auth/find-password")
);
const AuthSignInPage = React.lazy(() => import("./pages/auth/sign-in"));
const AuthSignUpFormPage = React.lazy(
  () => import("./pages/auth/sign-up/form")
);
const ProfileInformationPage = React.lazy(
  () => import("./pages/profile/information")
);
const AuthSignUpPage = React.lazy(() => import("./pages/auth/sign-up"));
const AuthChangePasswordPage = React.lazy(
  () => import("./pages/auth/change-password")
);
const ContractDetailPage = React.lazy(() => import("./pages/contract/detail"));
const ContractDashBoardDirectURLPage = React.lazy(
  () => import("./pages/contract/dashboard/direct-url")
);
const ContractDashBoardNormalURLPage = React.lazy(
  () => import("./pages/contract/dashboard/normal")
);
const ContractSendPage = React.lazy(() => import("./pages/contract/send"));
const SettingServicePage = React.lazy(() => import("./pages/setting/service"));
const SettingContactPage = React.lazy(() => import("./pages/setting/contact"));
const SettingFAQPage = React.lazy(() => import("./pages/setting/faq"));
const SettingGroupPage = React.lazy(() => import("./pages/setting/group"));
const SettingListBoxDBPage = React.lazy(
  () => import("./pages/setting/list-box-db")
);
const SettingMailPage = React.lazy(() => import("./pages/setting/mail"));
const SettingMemberPage = React.lazy(() => import("./pages/setting/member"));
const SettingOrganizationPage = React.lazy(
  () => import("./pages/setting/organization")
);
const SettingPaymentBillingPage = React.lazy(
  () => import("./pages/setting/payment/billing")
);
const SettingPaymentDashBoardPage = React.lazy(
  () => import("./pages/setting/payment/dashboard")
);
const SettingPaymentHistoryPage = React.lazy(
  () => import("./pages/setting/payment/history")
);
const SettingSupportPage = React.lazy(() => import("./pages/setting/support"));
const TemplateDashBoardBookMarkPage = React.lazy(
  () => import("./pages/template/dashboard/bookmark")
);
const TemplateDashBoardMePage = React.lazy(
  () => import("./pages/template/dashboard/me")
);
const TemplateDashBoardSendPreviewPage = React.lazy(
  () => import("./pages/template/send/detail/preview")
);
const TemplateStorePage = React.lazy(() => import("./pages/template/store"));
const SettingPaymentBillingCompletePage = React.lazy(
  () => import("./pages/setting/payment/billing/complete")
);
const InvitationAcceptancePage = React.lazy(() => import("./pages/invitation"));
const Create = React.lazy(() => import("./pages/template/create"));
const Sign = React.lazy(() => import("./pages/sign"));
const Email = React.lazy(() => import("./pages/receiver/email"));
const PasswordVerification = React.lazy(
  () => import("./pages/receiver/verification/password")
);
const PhoneVerification = React.lazy(
  () => import("./pages/receiver/verification/phone")
);
const Confirm = React.lazy(() => import("./pages/receiver/confirm"));
const Callback = React.lazy(() => import("./pages/receiver/callback"));

const loading = <div>화면을 불러오는 중 입니다.</div>;

const queryClient = new QueryClient();

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={loading}>
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Suspense>
    ),
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: (
          <LandingLayout>
            <RootLandingPage />
          </LandingLayout>
        ),
      },
      {
        path: "/plan",
        element: (
          <LandingLayout>
            <PlanPage />
          </LandingLayout>
        ),
      },
      {
        path: "/faq",
        element: (
          <LandingLayout>
            <RootLayout>
              <LandingFaq />
            </RootLayout>
          </LandingLayout>
        ),
      },
      {
        path: "/support",
        element: (
          <LandingLayout>
            <RootLayout>
              <LandingSupportPage />
            </RootLayout>
          </LandingLayout>
        ),
      },
      {
        path: "auth",
        element: (
          <LandingLayout>
            <RootLayout>
              <Outlet />
            </RootLayout>
          </LandingLayout>
        ),

        children: [
          {
            path: "sign-in",
            element: <AuthSignInPage />,
          },
          {
            path: "sign-up",
            children: [
              {
                path: "form/:tokenId",
                element: <AuthSignUpFormPage />,
                loader: AuthSignUpFormLoader,
              },
              {
                index: true,
                element: <AuthSignUpPage />,
              },
            ],
          },
          {
            path: "find-password",
            element: <AuthFindPasswordPage />,
          },
          {
            path: "change-password",
            children: [
              {
                path: ":tokenId",
                loader: AuthChangePasswordLoader,
                element: <AuthChangePasswordPage />,
              },
            ],
          },
        ],
      },
      {
        path: "dashboard",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        loader: SignInLoader,
        children: [
          {
            index: true,
            element: <DashBoardPage />,
          },
        ],
      },
      {
        path: "profile",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        loader: SignInLoader,
        children: [
          {
            path: "information",
            element: <ProfileInformationPage />,
          },
          {
            path: "sign",
            element: <ProfileSignPage />,
          },
          {
            path: "invited-organization",
            element: <ProfileInvitedOrganizationPage />,
          },
        ],
      },
      {
        path: "template",
        loader: SignInLoader,
        children: [
          {
            path: "dashboard",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateDashboardPage />,
              },

              {
                path: "bookmark",
                element: <TemplateDashBoardBookMarkPage />,
              },
              {
                path: "me",
                element: <TemplateDashBoardMePage />,
              },
            ],
          },
          {
            path: ":templateId",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateDetailPage />,
              },
            ],
          },
          {
            path: "create",
            children: [
              {
                path: "upload",
                element: <Create />,
              },
              {
                path: ":templateId",
                children: [
                  {
                    path: "editor",
                    element: <Create />,
                  },
                ],
              },
            ],
          },
          {
            path: "send",
            children: [
              {
                path: ":templateId",
                element: (
                  <TemplateSendLayout>
                    <Outlet />
                  </TemplateSendLayout>
                ),
                children: [
                  {
                    path: "config",
                    children: [
                      {
                        path: "single",
                        element: <TemplateSendConfigSinglePage />,
                      },
                      {
                        path: "multiple",
                        element: <TemplateSendConfigMultiplePage />,
                      },
                    ],
                  },
                  {
                    path: "preview",
                    element: <TemplateDashBoardSendPreviewPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "store",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateStorePage />,
              },
            ],
          },
        ],
      },
      {
        path: "contract",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        loader: SignInLoader,
        children: [
          {
            path: "dashboard",
            children: [
              {
                path: "normal",
                element: <ContractDashBoardNormalURLPage />,
              },
              {
                path: "direct-url",
                element: <ContractDashBoardDirectURLPage />,
              },
            ],
          },
          {
            path: "send",
            children: [
              {
                path: "upload",
                element: <ContractSendPage />,
              },
              {
                path: ":contractId",
                children: [
                  {
                    path: "config",
                    element: <ContractSendPage />,
                  },
                  {
                    path: "editor",
                    element: <ContractSendPage />,
                  },
                ],
              },
            ],
          },
          {
            path: ":contractId",
            element: <ContractDetailPage />,
          },
        ],
      },
      {
        path: "setting",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        loader: SignInLoader,
        children: [
          {
            path: "organization",
            element: <SettingOrganizationPage />,
          },
          {
            path: "service",
            element: <SettingServicePage />,
          },
          {
            path: "payment",
            children: [
              {
                path: "dashboard",
                element: <SettingPaymentDashBoardPage />,
              },
              {
                path: "billing",
                element: <SettingPaymentBillingPage />,
              },
              {
                path: "billing/complete",
                element: <SettingPaymentBillingCompletePage />,
              },
              {
                path: "history",
                element: <SettingPaymentHistoryPage />,
              },
            ],
          },
          {
            path: "member",
            element: <SettingMemberPage />,
          },
          {
            path: "group",
            element: <SettingGroupPage />,
          },
          {
            path: "mail",
            element: <SettingMailPage />,
          },
          {
            path: "contact",
            element: <SettingContactPage />,
          },
          {
            path: "list-box-db",
            element: <SettingListBoxDBPage />,
          },
          {
            path: "support",
            element: <SettingSupportPage />,
          },
          {
            path: "faq",
            element: <SettingFAQPage />,
          },
        ],
      },
      {
        path: "api",
        loader: SignInLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        children: [
          {
            path: "getting-started",
            element: <APIGettingStartedPage />,
          },
          {
            path: "dashboard",
            element: <APIDashboardPage />,
          },
        ],
      },
      {
        path: "notice",
        loader: SignInLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        children: [
          {
            path: "dashboard",
            children: [
              {
                index: true,
                element: <NoticeDashboardPage />,
              },
              {
                path: ":noticeId",
                element: <NoticeDashboardDetailPage />,
              },
            ],
          },
          {
            path: "guide",
            element: <NoticeGuidePage />,
          },
        ],
      },
      {
        path: "alert",
        loader: SignInLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        children: [
          {
            path: "dashboard",
            element: <AlertDashboardPage />,
          },
        ],
      },
      {
        path: "sign",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Sign />,
          },
        ],
      },
      {
        path: "receiver",
        element: (
          <Receiverlayout>
            <Outlet />
          </Receiverlayout>
        ),
        children: [
          { path: "callback", element: <Callback /> },
          {
            path: "email",
            element: <Email />,
          },
          {
            path: "verification",
            children: [
              {
                path: "password",
                element: <PasswordVerification />,
              },
              {
                path: "phone",
                element: <PhoneVerification />,
              },
            ],
          },
          { path: "confirm", element: <Confirm /> },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },

      {
        path: "invitation/acceptance",
        element: <InvitationAcceptancePage />,
      },
    ],
  },
]);

export default routes;
