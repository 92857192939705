import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useSignatureQuery = () => {
  return useQuery({
    ...queries.signature.list,
  });
};

export default useSignatureQuery;
