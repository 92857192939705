import {
  getNoticesByIdRequest,
  getNoticesByIdResponse,
  getNoticesListRequest,
  getNoticesListResponse,
} from "models/Notice";
import getAPIResponseData from "utils/getAPIResponse";

export const getNoticesList = ({ size, page }: getNoticesListRequest) => {
  return getAPIResponseData<getNoticesListResponse>({
    url: "/notices/list",
    method: "GET",
    params: { size, page },
  });
};

export const getNoticesById = ({ id }: getNoticesByIdRequest) => {
  return getAPIResponseData<getNoticesByIdResponse>({
    url: `/notices/${id}`,
    method: "GET",
  });
};
