import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import Stamp from "components/Profile/Stamp";
import { TEXT_INFO } from "components/Profile/type";
import { Body1Regular } from "components/TextStyle";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import useFontLoad from "hooks/useFontLoad";
import { Stage as StageRefType } from "konva/lib/Stage";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import konvaToImage from "utils/konvaToImage";
import resizeImage from "utils/resizeImage";

export const STAMP_FONT = [
  {
    fontFamily: "MapoAgape",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoAgapeA.woff",
    format: "woff",
  },
  {
    fontFamily: "ChosunGs",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.0/ChosunGs.woff",
    format: "woff",
  },
  {
    fontFamily: "WandohopeR",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/WandohopeR.woff",
    format: "woff",
  },
  {
    fontFamily: "NotoSerifKR",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NotoSerifKR.woff",
    format: "woff",
  },
  {
    fontFamily: "SUIT-Regular",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2",
    format: "woff2",
  },
  {
    fontFamily: "ONE-Mobile-Title",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Title.woff",
    format: "woff",
  },
  {
    fontFamily: "SuseongHyejeong",
    url: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2205-2@1.0/SuseongHyejeong.woff2",
    format: "woff2",
  },
  {
    fontFamily: "Wolin",
    url: "https://cdn.jsdelivr.net/gh/fontbee/font@main/Yoondesign/Wolin.woff",
    format: "woff",
  },
];

interface FormData {
  stampName: string;
}

interface StampContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const StampContent = ({
  closeModal,
  signMode = false,
  sign,
}: StampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const fontLoaded = useFontLoad(STAMP_FONT);
  const [stampName, setStampName] = useState("이폼서명");
  const { register, handleSubmit } = useForm<FormData>();
  const [selectedStamp, setSelectedStamp] = useState<{
    id: string;
    ref?: React.MutableRefObject<StageRefType | null>;
  }>({
    id: "",
    ref: undefined,
  });

  const handleGeneratorSubmit = (data: FormData) => {
    setStampName(data.stampName);
  };

  const handleCreateStamp = async () => {
    if (!selectedStamp.ref?.current) return;

    const scaleSize = resizeImage(
      {
        width: selectedStamp.ref.current.width(),
        height: selectedStamp.ref.current.height(),
      },
      {
        width: 300,
        height: 300,
      }
    );

    const file = await konvaToImage(selectedStamp.ref.current, {
      width: 300,
      height: 300,
      ...scaleSize,
    });

    if (signMode && sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  return (
    <>
      <Body1Regular>
        다양한 모양의 도장을 만들고 마음에 드는 도장을 입력해 보세요.
      </Body1Regular>
      <StampGeneratorForm onSubmit={handleSubmit(handleGeneratorSubmit)}>
        <Input
          placeholder="이름을 입력하고 만들기 버튼을 눌러주세요"
          {...register("stampName")}
        />
        <Button colorType="primary" size="large">
          만들기
        </Button>
      </StampGeneratorForm>
      <StampListLayout>
        {STAMP_FONT.map((stampType) => {
          const textInfo = TEXT_INFO.find(
            (info) => info.textLength === stampName.length
          );

          if (!textInfo || !fontLoaded) return;
          return textInfo.shapes.map((shape) => {
            return (
              <Stamp
                key={stampType.fontFamily + shape.id}
                fontFamily={stampType.fontFamily}
                name={stampName}
                shape={shape}
                onClick={({ ref }) => {
                  setSelectedStamp({
                    ref,
                    id: stampType.fontFamily + shape.id,
                  });
                }}
                isSelected={
                  selectedStamp.id === stampType.fontFamily + shape.id
                }
              />
            );
          });
        })}
      </StampListLayout>

      {signMode ? (
        <ModalFooter>
          <Button colorType="tertiary" size="large" onClick={closeModal}>
            취소
          </Button>
          <Button colorType="primary" size="large" onClick={handleCreateStamp}>
            확인
          </Button>
        </ModalFooter>
      ) : (
        <CreateButton
          colorType="primary"
          size="large"
          disabled={!selectedStamp.ref}
          onClick={handleCreateStamp}
        >
          도장 생성하기
        </CreateButton>
      )}
    </>
  );
};

export default StampContent;

const StampListLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  column-gap: 1.2rem;
  row-gap: 3rem;
  margin-top: 16px;
  height: 30rem;
  overflow-y: scroll;
`;

export const StampGeneratorForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 2rem;

  input {
    width: 37rem;
  }
`;

const CreateButton = styled(Button)`
  display: block;
  margin: 3.6rem auto 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 5.6rem;
  > button {
    width: 24rem;
  }
`;
