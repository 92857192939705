import { Organization } from "models/Organization";
import {
  GetOrganizationsGroupsMembersResponse,
  GetOrganizationsGroupByIdGroupListResponse,
  PatchOrganizationsGroupsNameRequest,
  PatchOrganizationsGroupsMembersRoleRequest,
  GetOrganizationsGroupsResponse,
  PostOrganizationsGroupsMembersRequest,
} from "models/OrganizationGroup";
import getAPIResponseData from "utils/getAPIResponse";

export const getOrganizationsGroupByIdGroupList = (
  id: Organization["uuid"]
) => {
  return getAPIResponseData<GetOrganizationsGroupByIdGroupListResponse>({
    url: `/organizations/groups/${id}/group-list`,
    method: "GET",
  });
};

export const getOrganizationsGroupMembers = (
  organizationGroupUuid?: string
) => {
  return getAPIResponseData<GetOrganizationsGroupsMembersResponse[]>({
    url: `/organizations/groups/members/${organizationGroupUuid}`,
    method: "GET",
  });
};

export const postOrganizationsGroups = ({
  organizationUuid,
  name,
}: {
  organizationUuid: string;
  name: string;
}) => {
  return getAPIResponseData({
    url: `/organizations/groups`,
    method: "POST",
    data: {
      organizationUuid,
      name,
    },
  });
};

export const patchOrganizationsGroupsName = (
  data: PatchOrganizationsGroupsNameRequest
) => {
  return getAPIResponseData({
    url: "/organizations/groups/name",
    method: "PATCH",
    data: data,
  });
};

export const deleteOrganizationsGroups = ({
  organizationGroupUuid,
}: {
  organizationGroupUuid: string;
}) => {
  return getAPIResponseData({
    url: `/organizations/groups/${organizationGroupUuid}`,
    method: "DELETE",
  });
};

export const patchOrganizationsGroupsMembersRole = (
  data: PatchOrganizationsGroupsMembersRoleRequest
) => {
  return getAPIResponseData({
    url: "/organizations/groups/members/role",
    method: "PATCH",
    data: data,
  });
};

export const getOrganizationsGroups = ({
  organizationGroupUuid,
  searchKeyword,
}: {
  organizationGroupUuid: string;
  searchKeyword: string;
}) => {
  return getAPIResponseData<GetOrganizationsGroupsResponse[]>({
    url: `/organizations/groups/${organizationGroupUuid}`,
    method: "GET",
    params: { searchKeyword },
  });
};

export const postOrganizationsGroupsMembers = (
  data: PostOrganizationsGroupsMembersRequest
) => {
  return getAPIResponseData({
    url: "/organizations/groups/members",
    method: "POST",
    data: data,
  });
};

export const deleteOrganizationsGroupsMembers = ({
  organizationGroupUuid,
  organizationMemberUuid,
}: {
  organizationGroupUuid: string;
  organizationMemberUuid: string;
}) => {
  return getAPIResponseData({
    url: `/organizations/groups/members/${organizationGroupUuid}/${organizationMemberUuid}`,
    method: "DELETE",
  });
};
