import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useServicePlanQuery = () => {
  return useQuery({
    ...queries.payment.plan,
  });
};

export default useServicePlanQuery;
