import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getDirectUrlById,
  getDirectUrlManagement,
  getDirectUrlParticipantsInfo,
  getDirectUrls,
} from "apis/directURL";
import {
  GetDirectUrlByIdRequest,
  GetDirectUrlManagementRequest,
  GetDirectUrlParticipantsInfoRequest,
  GetDirectUrlsRequest,
} from "models/Contract";
import { PartialOrganization } from "./contract";

export const directURLQueryKey = createQueryKeys("directURL", {
  list: (options: PartialOrganization<GetDirectUrlsRequest>) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getDirectUrls(options as GetDirectUrlsRequest);
    },
  }),
  edit: (options: PartialOrganization<GetDirectUrlByIdRequest>) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getDirectUrlById(options as GetDirectUrlByIdRequest);
    },
  }),
  management: (
    options: PartialOrganization<GetDirectUrlManagementRequest>
  ) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getDirectUrlManagement(options as GetDirectUrlManagementRequest);
    },
  }),
  participant: (
    options: PartialOrganization<GetDirectUrlParticipantsInfoRequest>
  ) => ({
    queryKey: [options],
    queryFn: () => {
      if (!options.organizationId) {
        return;
      }
      return getDirectUrlParticipantsInfo(
        options as GetDirectUrlParticipantsInfoRequest
      );
    },
  }),
});
