type Size = {
  width: number;
  height: number;
};

const resizeImage = (origin: Size, resize: Size) => {
  const originalAspectRatio = origin.width / origin.height;

  let scaledWidth, scaledHeight, offsetX, offsetY;

  if (originalAspectRatio > 1) {
    // 원본이 가로로 긴 경우
    scaledWidth = resize.width;
    scaledHeight = resize.width / originalAspectRatio;
    offsetX = 0;
    offsetY = (resize.height - scaledHeight) / 2;
  } else {
    // 원본이 세로로 긴 경우
    scaledHeight = resize.height;
    scaledWidth = resize.height * originalAspectRatio;
    offsetX = (resize.width - scaledWidth) / 2;
    offsetY = 0;
  }

  return {
    scaleX: scaledWidth / origin.width,
    scaleY: scaledHeight / origin.height,
    offsetX,
    offsetY,
  };
};

export default resizeImage;
