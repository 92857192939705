import React, { PropsWithChildren } from "react";
import AppHeader from "../components/AppHeader";
import styled from "styled-components";
import LNB from "components/LNB";
import { useLocation } from "react-router-dom";
import { CONTRACT_SEND_PATH } from "config/constant";
import Footer from "components/Footer";
import { RootLayout } from "./RootLayout";

const DefaultLayout = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  /*const navigate = useNavigate()

  // 페이지 첫 로딩시 로직
  useEffect(() => {
    // 벨리데이션
    if (!user.name) {
      alert('로그인을 해주세요')
      navigate('/login')
    }
  }, [])*/

  return (
    <>
      {pathname.startsWith(CONTRACT_SEND_PATH) ? (
        <>
          <AppHeader contract />
          {children}
        </>
      ) : (
        <RootLayout>
          <AppHeader />
          <MainContent>
            <LNB />
            <ContentLayout>
              <MainContentLayout>{children}</MainContentLayout>
              <Footer />
            </ContentLayout>
          </MainContent>
        </RootLayout>
      )}
    </>
  );
};

const MainContent = styled.div`
  display: flex;
  min-height: 100%;
  align-items: stretch;

  flex: 1;
`;

const ContentLayout = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`;
export const MainContentLayout = styled.main`
  padding: 3rem 5rem 10rem 3rem;
  max-width: 162rem;
  min-width: 114rem;
  margin: 0 auto;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
`;

export default DefaultLayout;
