import React, { MouseEvent } from "react";
import { CellEllipsis, TableCell, TableRow } from "../Table/Table";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { Body2Regular } from "components/TextStyle";
import DropdownMenu from "components/Common/Dropdown/DropdownMenu";

import { ReactComponent as StarOnIcon } from "assets/common/20px/icon_star_on.svg";
import { ReactComponent as StarOffIcon } from "assets/common/20px/icon_star_off.svg";
import { ReactComponent as KebabIcon } from "assets/common/24px/icon_kebab.svg";
import DashboardRowSendButton from "./DashboardRowSendButton";
import useModal from "hooks/useModal";
import TemplateDeleteModal from "../Modals/TemplateDeleteModal";
import TemplateDataExportModal from "../Modals/TemplateDataExportModal";
import { useNavigate } from "react-router-dom";
import TemplateDirectUrlModal from "../Modals/TemplateDirectUrlModal";
import useTemplateFavoriteMutation from "hooks/mutations/template/useTemplateFavoriteMutation";
import useUserState from "hooks/recoil/useUserState";
import useDeleteTemplateFavoriteMutation from "hooks/mutations/template/useDeleteTemplateFavoriteMutation";
import useDeleteTemplateMutation from "hooks/mutations/template/useDeleteTemplateMutation";

interface DashboardRowProps {
  id: string;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isBookmark: boolean;
  status: "COMPLETE" | "TEMP";
  templateName: string;
  receiverCount: number;
  register: string;
  createdAt: string;
  updatedAt: string;
}
const DashboardRow = ({
  id,
  onChecked,
  isChecked,
  isBookmark,
  status,
  templateName,
  receiverCount,
  register,
  createdAt,
  updatedAt,
}: DashboardRowProps) => {
  const navigate = useNavigate();
  const [user] = useUserState();
  const { mutate: favoriteMutate } = useTemplateFavoriteMutation();
  const { mutate: unFavoriteMutate } = useDeleteTemplateFavoriteMutation();
  const { mutate: deleteMutate } = useDeleteTemplateMutation();

  const STATUS_MAP = {
    COMPLETE: {
      label: "완료",
      color: "var(--text-default)",
    },
    TEMP: {
      label: "임시",
      color: "var(--text-emphasis)",
    },
  } as const;

  const {
    isOpen: deleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const {
    isOpen: dataExportModalOpen,
    closeModal: closeDataExportModal,
    openModal: openDataExportModal,
  } = useModal();

  const {
    isOpen: directURLModalOpen,
    closeModal: closeDirectURLModal,
    openModal: openDirectURLModal,
  } = useModal();

  const handleFavorite = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    favoriteMutate({
      organizationId: user.organization ?? "",
      templateId: id,
    });
  };

  const handleUnFavorite = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();

    unFavoriteMutate({
      organizationId: user.organization ?? "",
      templateId: id,
    });
  };

  const handleDelete = () => {
    deleteMutate({
      organizationId: user.organization ?? "",
      templateIdList: [id],
    });
  };

  const handleRowClick = () => {
    navigate(`/template/${id}`);
  };

  return (
    <>
      {deleteModalOpen && (
        <TemplateDeleteModal
          closeModal={closeDeleteModal}
          handleDelete={handleDelete}
        />
      )}

      {dataExportModalOpen && (
        <TemplateDataExportModal closeModal={closeDataExportModal} />
      )}

      {directURLModalOpen && (
        <TemplateDirectUrlModal closeModal={closeDirectURLModal} />
      )}

      <TableRow
        onClick={handleRowClick}
        style={{
          cursor: "pointer",
        }}
      >
        <TableCell>
          <CheckBox checked={isChecked} onChange={onChecked} />
        </TableCell>
        <TableCell>
          {isBookmark ? (
            <StarOnIcon onClick={handleUnFavorite} />
          ) : (
            <StarOffIcon onClick={handleFavorite} />
          )}
        </TableCell>
        <TableCell>
          <Body2Regular
            style={{
              color: STATUS_MAP[status].color,
            }}
          >
            {STATUS_MAP[status].label}
          </Body2Regular>
        </TableCell>
        <TableCell>
          <CellEllipsis>{templateName}</CellEllipsis>
        </TableCell>
        <TableCell>
          <Body2Regular>{register}</Body2Regular>
        </TableCell>
        <TableCell>
          <Body2Regular>{createdAt}</Body2Regular>
        </TableCell>
        <TableCell>
          <Body2Regular>{updatedAt}</Body2Regular>
        </TableCell>
        <TableCell>
          <DashboardRowSendButton
            isDisabled={status === "TEMP"}
            list={[
              ...(receiverCount <= 1
                ? [
                    {
                      label: "Direct URL 만들기",
                      onClick: openDirectURLModal,
                    },
                  ]
                : []),
              {
                label: "대량 전송하기",
                onClick: () => {
                  navigate(`/template/send/${id}/config/multiple`);
                },
              },
            ]}
            id={id}
          />
        </TableCell>
        <TableCell>
          <DropdownMenu
            list={
              status === "TEMP"
                ? [
                    {
                      label: "수정하기",
                      onClick: () =>
                        navigate(`/template/create/upload?id=${id}`),
                    },
                    {
                      label: "삭제",
                      onClick: openDeleteModal,
                    },
                  ]
                : [
                    {
                      label: "문서보기",
                    },
                    {
                      label: "원본 다운로드",
                    },
                    {
                      label: "템플릿 데이터 받기",
                      onClick: openDataExportModal,
                    },
                    {
                      label: "삭제",
                      onClick: openDeleteModal,
                    },
                  ]
            }
          >
            <KebabIcon />
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DashboardRow;
