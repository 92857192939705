import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import { GetTemplateDetailInfoRequest } from "models/Template";

const useTemplateDetailInfoQuery = ({
  organizationId = "",
  templateId = "",
}: Partial<GetTemplateDetailInfoRequest>) => {
  return useQuery({
    ...queries.template.detailInfo({ organizationId, templateId }),
    enabled: !!organizationId && !!templateId,
  });
};

export default useTemplateDetailInfoQuery;
