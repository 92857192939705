import React, { useRef } from "react";
import { Layer, Stage, Text } from "react-konva";
import styled from "styled-components";
import { Stage as StageRefType } from "konva/lib/Stage";
import splitTextChunk from "utils/splitTextChunk";
import { TEXT_INFO_TYPE } from "./type";
import { PickArrayItem } from "utils/types";

interface StampProps {
  fontFamily: string;
  name: string;
  shape: PickArrayItem<Pick<TEXT_INFO_TYPE, "shapes">["shapes"]>;
  onClick?: ({
    imgURL,
    event,
    ref,
  }: {
    imgURL: string | undefined;
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    ref: React.MutableRefObject<StageRefType | null>;
  }) => void;
  isSelected: boolean;
}

const STROKE_WIDTH = 4;

const Stamp = ({
  fontFamily,
  name,
  shape,
  onClick,
  isSelected,
}: StampProps) => {
  const stageRef = useRef<StageRefType | null>(null);
  const chunks = splitTextChunk(name, shape.chunkSize);
  const textHeight = shape.fontSize;
  const textWidth = shape.fontSize;

  const totalHeight = shape.stamp.height;
  const totalWidth = shape.stamp.width;

  return (
    <>
      <StampBoard
        onClick={(e) =>
          onClick &&
          onClick({
            imgURL: stageRef.current?.toDataURL(),
            event: e,
            ref: stageRef,
          })
        }
        isSelected={isSelected}
      >
        <Stage width={totalWidth} height={totalHeight} ref={stageRef}>
          <Layer>
            <shape.stamp.OutlineStamp
              width={totalWidth}
              height={totalHeight}
              strokeWidth={STROKE_WIDTH}
            />
            {chunks.map((chunk, index) => (
              <Text
                key={index}
                x={(totalWidth - shape.chunkSize * textWidth) / 2}
                y={
                  (totalHeight - chunks.length * textHeight) / 2 +
                  index * textHeight
                }
                text={chunk}
                fontSize={shape.fontSize}
                fontFamily={fontFamily}
                fill="#E40E14"
                height={textHeight}
                width={textWidth * shape.chunkSize}
                align="center"
                verticalAlign="middle"
              />
            ))}
          </Layer>
        </Stage>
      </StampBoard>
    </>
  );
};

export default Stamp;

const StampBoard = styled.div<{
  isSelected: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f8;
  border-radius: 1.2rem;
  min-width: 24rem;
  min-height: 24rem;
  cursor: pointer;
  border: ${({ isSelected }) =>
    isSelected ? "1.5px solid var(--primary-500)" : "none"};
`;
