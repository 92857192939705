import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getTemplateStore } from "apis/templateStore";
import { GetTemplateStoreRequest } from "models/templateStore";

export const templateStoreQueryKey = createQueryKeys("templateStore", {
  list: ({ options }: { options: GetTemplateStoreRequest }) => ({
    queryKey: [options],
    queryFn: () => getTemplateStore({ options }),
  }),
});
