import { postAuthSignUpSessionCodes } from "apis/auth";
import { json, Params, redirect } from "react-router-dom";

const AuthSignUpFormLoader = async ({ params }: { params: Params<string> }) => {
  try {
    const result = await postAuthSignUpSessionCodes({
      sessionCode: params.tokenId || "",
    });
    return json({ email: result.email, sessionCode: params.tokenId });
  } catch (err) {
    return redirect("/");
  }
};

export default AuthSignUpFormLoader;
