import NoticeNavigation from "components/Notice/NoticeNavigation";
import { Board } from "pages/dashboard/style";
import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { Body3Regular } from "components/TextStyle";

const GUIDE_LIST = [
  {
    subTitle: "처음 오셨나요?",
    title: "01. 퀵 가이드 다운로드",
    link: "",
  },
  {
    subTitle: "기존 고객님이신가요?",
    title: "02. 변경 부분 가이드 다운로드",
    link: "",
  },
  {
    subTitle: "및춤형 서비스를 찾으시나요?",
    title: "03. 브랜딩 서비스 가이드 다운로드",
    link: "",
  },
];

const NoticeGuidePage = () => {
  return (
    <>
      <NoticeNavigation />
      <NoticeGuideBoard>
        <NoticeGuideLayout>
          <video width="600" controls>
            <source src="path/to/your/video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <GuideListLayout>
            {GUIDE_LIST.map((guide) => {
              return (
                <div key={guide.link}>
                  <GuideListItem>
                    <h4>{guide.subTitle}</h4>
                    <div className="guide-list-title">
                      <h2>{guide.title}</h2>
                      <RightArrowIcon />
                    </div>
                  </GuideListItem>
                  <hr />
                </div>
              );
            })}
            <a className="add-more-guide" href="">
              <Body3Regular>더 많은 도움이 필요하신가요</Body3Regular>
              <RightArrowIcon />
            </a>
          </GuideListLayout>
        </NoticeGuideLayout>
      </NoticeGuideBoard>
    </>
  );
};

export default NoticeGuidePage;

const NoticeGuideBoard = styled(Board)`
  width: 100%;
  padding: 10rem 0;
`;

const NoticeGuideLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11rem;

  width: fit-content;
  margin: auto;
`;

const GuideListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5.4rem;

  max-width: 60rem;
  width: 100%;

  .guide-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .add-more-guide {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: var(--stroke-light);
    border: none;
    margin: 0;
  }

  > hr:last-child {
    display: none;
  }
`;

const GuideListItem = styled.li`
  margin-bottom: 5.4rem;
  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;

    margin: 0 0 1.2rem 0;

    color: var(--text-secondary);
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px; /* 136.364% */
    letter-spacing: -0.66px;

    margin: 0;
  }
`;
