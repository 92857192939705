import AuthHeader from "components/AuthHeader";
import BoundaryInViewProvider from "components/Common/BoundaryInView/BoundaryInViewProvider";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const LandingLayout = ({ children }: PropsWithChildren) => {
  return (
    <BoundaryInViewProvider>
      <AuthHeader />
      <ContentLayout>{children}</ContentLayout>
    </BoundaryInViewProvider>
  );
};

export default LandingLayout;

const ContentLayout = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: var(--bg-white);
`;
