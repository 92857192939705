import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSignaturesById } from "apis/signature";
import { queries } from "config/queryKey";

const useSignaturesDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSignaturesById,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.signature.list.queryKey,
      });
    },
  });
};

export default useSignaturesDeleteMutation;
