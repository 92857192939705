import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getMailTemplateAll, getMailTemplateDetail } from "apis/mailTemplate";

export const mailTemplateQueryKey = createQueryKeys("mailTemplate", {
  list: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => getMailTemplateAll(organizationUuid),
  }),
  detail: (mailTemplateUuid?: string) => ({
    queryKey: [mailTemplateUuid],
    queryFn: () => getMailTemplateDetail(mailTemplateUuid),
  }),
});
