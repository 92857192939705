import { useInfiniteQuery } from "@tanstack/react-query";
import { getNotification } from "apis/notification";
import { queries } from "config/queryKey";

const useNotificationList = () => {
  return useInfiniteQuery({
    queryKey: queries.notification.list(10).queryKey,
    queryFn: ({ pageParam }) => getNotification({ size: 10, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) =>
      lastPage.notificationList.hasNextPage
        ? lastPage.notificationList.page + 1
        : undefined,
  });
};

export default useNotificationList;
