import Toggle from "components/Common/Toggle/Toggle";
import React from "react";
import { Control, Controller, Path } from "react-hook-form";

interface ToggleControlProps<T extends object> {
  name: Path<T>;
  control: Control<T>;
}

const ToggleController = <T extends object>({
  control,
  name,
}: ToggleControlProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Toggle
          isSelected={!!value}
          handleToggle={() => onChange(!value)}
          ref={ref}
        />
      )}
    />
  );
};

export default ToggleController;
