import {
  getNotificationRequest,
  getNotificationResponse,
  getNotificationTodayResponse,
  patchNotificationRequest,
} from "models/Notification";
import getAPIResponseData from "utils/getAPIResponse";

export const getNotification = ({ size, page }: getNotificationRequest) => {
  return getAPIResponseData<getNotificationResponse>({
    url: "/notifications",
    method: "GET",
    params: { size, page },
  });
};

export const getNotificationToday = () => {
  return getAPIResponseData<getNotificationTodayResponse>({
    url: `/notifications/today`,
    method: "GET",
  });
};

export const patchNotification = ({ id }: patchNotificationRequest) => {
  return getAPIResponseData({
    url: `/notifications/${id}`,
    method: "PATCH",
  });
};
