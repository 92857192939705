import React from "react";
import {
  LNBContainer,
  LNBItemContainer,
  LNBItemsContainer,
  LNBSubItemsContainer,
} from "./style";

import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem, SubMenuItem } from "./MenuItems";
import { MENU_ITEMS } from "./MenuItemsConstant";

export interface MenuItems {
  label: string;
  icon: string;
  activeIcon: string;
  to: string;
  include?: string[];
  subMenu?: { label: string; to: string; include: string[] }[];
}

const LNB = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <LNBContainer>
      <LNBItemsContainer>
        {MENU_ITEMS.map((menuItem: MenuItems, idx: number) => {
          const { subMenu, to, include } = menuItem;
          const isSelectedMenu =
            pathname.split("/")[1].includes(to.split("/")[1]) ||
            include?.some((sub) =>
              sub.split("/")[1].includes(pathname.split("/")[1])
            );

          const isIncludeSubMenu =
            subMenu?.some((sub) => sub.include.includes(pathname)) ?? false;

          const handleMenu = () => navigate(to);

          return (
            <LNBItemContainer key={idx}>
              <MenuItem
                handleClick={handleMenu}
                isSelected={isSelectedMenu || isIncludeSubMenu}
                menuItem={menuItem}
              />

              {isIncludeSubMenu && (
                <LNBSubItemsContainer>
                  {subMenu?.map((subMenuItem, idx: number) => {
                    const isSelectedSubMenu =
                      subMenuItem.include.includes(pathname);
                    const handleSubMenu = () => navigate(subMenuItem.to);

                    return (
                      <SubMenuItem
                        key={idx}
                        isSelected={isSelectedSubMenu}
                        subMenuItem={subMenuItem}
                        handleClick={handleSubMenu}
                      />
                    );
                  })}
                </LNBSubItemsContainer>
              )}
            </LNBItemContainer>
          );
        })}
      </LNBItemsContainer>
    </LNBContainer>
  );
};

export default LNB;
