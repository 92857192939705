import { postAuthPasswordSessionCodes } from "apis/auth";
import { json, Params, redirect } from "react-router-dom";

const AuthChangePasswordLoader = async ({
  params,
}: {
  params: Params<string>;
}) => {
  try {
    await postAuthPasswordSessionCodes({
      sessionCode: params.tokenId || "",
    });
    return json({ sessionCode: params.tokenId });
  } catch (err) {
    return redirect("/");
  }
};

export default AuthChangePasswordLoader;
