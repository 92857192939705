import getAPIResponseData from "utils/getAPIResponse";

export const getMailTemplateAll = (organizationId?: string) => {
  return getAPIResponseData<GetMailTemplateAllRes[]>({
    url: `/easy-setup/mail-template/all/${organizationId}`,
    method: "GET",
  });
};

export const postMailTemplate = (data: PostMailTemplateReq) => {
  return getAPIResponseData({
    url: "/easy-setup/mail-template",
    method: "POST",
    data: data,
  });
};

export const patchMailTemplate = (data: PatchMailTemplateReq) => {
  return getAPIResponseData({
    url: `/easy-setup/mail-template/${data.mailTemplateUuid}`,
    method: "PATCH",
    data: {
      name: data.name,
      title: data.title,
      content: data.content,
    },
  });
};

export const getMailTemplateDetail = (mailTemplateUuid?: string) => {
  return getAPIResponseData<GetMailTemplateDetailRes>({
    url: `/easy-setup/mail-template/detail/${mailTemplateUuid}`,
    method: "GET",
  });
};

export const deleteMailTemplate = (data: DeleteMailTemplateReq) => {
  return getAPIResponseData({
    url: `/easy-setup/mail-template/batch`,
    method: "DELETE",
    data,
  });
};
